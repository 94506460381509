import * as React from 'react';
import { useState, useEffect } from 'react'

import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import Link from '@mui/material/Link';

import { useSearchParams } from "react-router-dom";
import LoadingBlock from '../views/utils/loadingBlock';
import { ArticlesModel } from '../models/stateful/articlesModel';
import { createTheme, ThemeProvider, styled } from '@mui/material/styles';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import MainAppBar from './header';
import Footer from './footer';

import ImageCard from './demo/imageCard';
import VideoCard from './demo/videoCard';


import {
    EmailShareButton,
    FacebookShareButton,
    TwitterShareButton,
    WhatsappShareButton,
  } from "react-share";

  import {
    EmailIcon,
    FacebookIcon,
    TwitterIcon,
    WhatsappIcon,
  } from "react-share";


import FadeAlert from '../views/utils/fadeAlert';

const Title = styled(Paper)(({ theme }) => ({
    ...theme.typography.body2,
    textAlign: 'center',
    height: 100,
    marginTop: '30px',
    paddingTop: '15px',
    color: theme.palette.text.secondary,
  }));

const Content = styled(Paper)(({ theme }) => ({
    ...theme.typography.body2,
    textAlign: 'left',
    color: theme.palette.text.secondary,
  }));

  function Copy2ClipboardTitle(props) {
    const copyTitle = () => {
        var title = document.getElementById(props.topicId + '-title').innerText;
        navigator.clipboard.writeText(title);
        props.setShowAlert(true);
    }
    return (<Grid container>
        <Grid item xs={11}>{props.component}</Grid>
        <Grid item xs={1} >
            <IconButton aria-label="content-copy" color="primary" onClick={copyTitle}>
                <ContentCopyIcon />
            </IconButton>
        </Grid>
    </Grid>);
}

function Copy2ClipboardContent(props) {
    const copyContent = () => {
        var content = document.getElementById(props.topicId + '-' + props.type).innerText;
        navigator.clipboard.writeText(content);
        props.setShowAlert(true);
    }
    const twitter_tags = ["aigc","ai","social_media","automation"];
    return (<Grid container>
        <Grid item xs={9.5} />
        <Grid item sx={{textAlign: 'right'}}>
            <IconButton aria-label="content-copy" color="primary" size='large' onClick={copyContent}>
                <ContentCopyIcon />
            </IconButton>
        </Grid>
        <Grid item sx={{textAlign: 'right', paddingTop: '10px', paddingRight: '10px'}}>
            <EmailShareButton url="https://huhu.ai"
                subject="I wrote a stunning blog with a few clicks on huhu.ai, try it out!"
                body="No idea what to ask ChatGPT? <br/>Feeling painful to copy-paste contents anywhere? <br/>
                      Waking up at midnight to post your masterpieces? <br/><br/>Here's what we are offering:<br/><br/>
                Millions of daily trending topics for you to roll dice to pick<br/>
                Handsfree content generation (text, images, and tags) powered by cutting-edge AI<br/>
                Schedule to post anytime while you enjoy sunshine at Hawaii<br/>
                APIs for all above<br/>"
                separator=' '
            >
                <EmailIcon size={32} round={true} />
            </EmailShareButton>
        </Grid>
        <Grid item sx={{textAlign: 'right', paddingTop: '10px', paddingRight: '10px'}}>
            <FacebookShareButton url="https://huhu.ai"
                hashtag="#aigc"
                quote="I wrote a stunning blog with a few clicks on huhu.ai, try it out!">
                <FacebookIcon size={32} round={true} />
            </FacebookShareButton>
        </Grid>
        <Grid item sx={{textAlign: 'right', paddingTop: '10px', paddingRight: '10px'}}>
            <TwitterShareButton url="https://huhu.ai" 
                title="I wrote a stunning blog with a few clicks on huhu.ai, try it out!"
                hashtags={twitter_tags} >
                <TwitterIcon size={32} round={true} />
            </TwitterShareButton>
        </Grid>
        <Grid item sx={{textAlign: 'right', paddingTop: '10px', paddingRight: '50px'}}>
            <WhatsappShareButton url="https://huhu.ai" 
              title="I wrote a stunning blog with a few clicks on huhu.ai, try it out!"
              separator=" "
            >
                <WhatsappIcon size={32} round={true} />
            </WhatsappShareButton>
        </Grid>
        <Grid item xs={6} sx={{textAlign: 'center', paddingLeft: '50px', paddingTop: '10px', paddingBottom: '10px'}}>

                

                {props.isVideo && <VideoCard topicId={props.topicId} />}
                {!props.isVideo && <ImageCard topicId={props.topicId} />}
        </Grid>
        <Grid item xs={6}>{props.component}</Grid>
    </Grid>);
}

function Copy2ClipboardMain(props) {
    const copyContent = () => {
        var content = document.getElementById(props.topicId + '-' + props.type).innerText;
        navigator.clipboard.writeText(content);
        props.setShowAlert(true);
    }
    return (<Grid container>
        <Grid item xs={11} />
        <Grid item sx={{textAlign: 'right'}}>
            <IconButton aria-label="content-copy" color="primary" size='large' onClick={copyContent}>
                <ContentCopyIcon />
            </IconButton>
        </Grid>
        <Grid item xs={12}>{props.component}</Grid>
    </Grid>);
}

let topicId = '';

export default function Article(props) {
    const [title, setTitle] = React.useState('');
    const [searchParams, setSearchParams] = useSearchParams();
    const [loading, setLoading] = React.useState(true);
    const [content, setContent] = React.useState('');
    const [shortVersion, setShortVersion] = React.useState('');
    const [tags, setTags] = React.useState('');
    const [showAlert, setShowAlert] = React.useState(false);
    const [isVideo, setIsVideo] = React.useState(false);

    topicId = searchParams.get('topic_id');
    let articlesHandler = new ArticlesModel();

    const callback = (response) => {
        const tag_list = response.Tags.split(',');
        setTitle(response.Title);
        setContent(response.Content + 
            '(The content is generated by <a href="https://huhu.ai" style="text-decoration:none;color:black;">https://huhu.ai</a>)');
        setShortVersion(response.ShortVersion + ' (By <a href="https://huhu.ai" style="text-decoration:none;color:black;">https://huhu.ai</a>)');
        setTags(response.Tags);
        setLoading(false);
        if (response.VideoUrl != '') {
            setIsVideo(true);
        }
    }
    articlesHandler.list(topicId, callback)

      if (loading) {
          return (<LoadingBlock />);
      } else {
          return (
            <>
            <MainAppBar />
          <Grid container alignItems="center" justifyContent="center">
            {showAlert ? (<Grid item xs={12}><FadeAlert children="Copied to your clipboard!" show={showAlert} setShow={setShowAlert}/></Grid>) : null}

            <Grid item xs={11}><Grid item xs={12} sx={{textAlign: 'center'}}></Grid>
                <Title elevation={3}>
                    <Copy2ClipboardTitle topicId={topicId} setShowAlert={setShowAlert}
                      component=<div id={topicId+'-title'} style={{ marginLeft:"60px",  marginRight:"60px"}} dangerouslySetInnerHTML={{__html: '<h2>' + title + '</h2>'}} /> 
                    />
                </Title>
                <Content elevation={3}>
                    <Copy2ClipboardContent type='content' topicId={topicId} setShowAlert={setShowAlert} isVideo={isVideo}
                    component=<div id={topicId+'-content'} style={{ marginLeft:"60px", marginRight:"60px", marginBottom:"60px", paddingBottom:"10px",fontSize:"16px" }} dangerouslySetInnerHTML={{__html: content}} /> />                    
                </Content>

                <Content elevation={3}>
                    <Copy2ClipboardMain type='short' topicId={topicId} setShowAlert={setShowAlert}
                    component=<div id={topicId+'-short'} style={{ marginLeft:"60px", marginRight:"60px", marginBottom:"30px", paddingBottom:"10px",fontSize:"20px" }} dangerouslySetInnerHTML={{__html: shortVersion}} /> />                    
                </Content>
                <Content elevation={3}>
                    
                <Copy2ClipboardMain type='tags' topicId={topicId} setShowAlert={setShowAlert}
                      component=<div 
                                  id={topicId+'-tags'} 
                                  style={{ marginLeft:"60px", marginRight:"60px", marginBottom:"30px", paddingBottom:"10px",fontSize:"20px" }} 
                                  dangerouslySetInnerHTML={{__html: tags}} 
                                />
                    />  
                </Content>

            </Grid>
          </Grid>
          <Footer />
          </>);
      }
}

