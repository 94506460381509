import React from "react";
import axios from "axios";

import { TokenHandler } from "../utils/token-handler.js";
import { get_access_token } from "../utils/authorization.js";

class FeedbackReview extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      requestList: [],
      currentRequest: null,
      selectedImage: null,
      accessToken: "",
      errorMessage: "", // Add errorMessage to state
      paginationToken: "",
    };
  }

  componentDidMount() {
    const tokenCallbackFunc = (jwt) => {
      this.setState({ accessToken: jwt }, () => {
        this.tokenHandler = new TokenHandler(jwt);
        this.fetchAllFeedback(); // Move this inside setState callback
      });
    };
    get_access_token(tokenCallbackFunc);
  }

  setSelectedImage = (image) => {
    this.setState({ selectedImage: image });
  };

  handleTimestampClick = (item) => {
    this.setState({ currentRequest: item });
  };

  closePopup = () => {
    this.setState({ currentRequest: null, selectedImage: null });
  };

  handleApproveClick = (request) => {
    if (request) {
      this.updateFeedback("1", request);
    }
  };

  handleRejectClick = (request) => {
    if (request) {
      this.updateFeedback("0", request);
    }
  };
  fetchAllFeedback = () => {
    axios
      .get(
        "https://n7jwv35eki.execute-api.us-west-2.amazonaws.com/prod/internal_tool/feedback_review",
        {
          params: {
            web_page: "feedback_review",
            pagination_token: this.state.paginationToken,
          },
          headers: {
            Authorization: `Bearer ${this.state.accessToken}`,
          },
        }
      )
      .then((response) => {
        if (!response.data || response.data.length === 0) {
          this.setState({ errorMessage: "No feedback available." });
        } else {
          this.setState({
            requestList: response.data.output,
            paginationToken: response.data.pagination_token,
          });
        }
      })
      .catch((error) => {
        this.setState({
          errorMessage: "An error occurred while fetching feedback.",
        });
      });
  };

  updateFeedback = (selectedAction, selectedRequest) => {
    axios
      .post(
        "https://n7jwv35eki.execute-api.us-west-2.amazonaws.com/prod/internal_tool/feedback_review",
        {
          review: selectedAction,
          request: selectedRequest,
        },
        {
          headers: {
            Authorization: `Bearer ${this.state.accessToken}`,
          },
        }
      )
      .then((response) => {
        if (!response.data || response.data.length === 0) {
          this.setState({ errorMessage: "No feedback available." });
        } else {
          this.setState({
            requestList: response.data.output,
            paginationToken: response.data.pagination_token,
          });
        }
      })
      .catch((error) => {
        this.setState({
          errorMessage: "An error occurred while fetching feedback.",
        });
      });
  };

  render() {
    return (
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          marginTop: "50px",
        }}
      >
        {this.state.errorMessage && (
          <div style={{ color: "red", marginBottom: "10px" }}>
            {this.state.errorMessage}
          </div>
        )}
        {this.state.paginationToken && (
          <button onClick={this.fetchAllFeedback} style={{ marginTop: "10px" }}>
            Next Page
          </button>
        )}
        {this.state.requestList.length > 0 && (
          <table
            style={{ border: "1px solid black", borderCollapse: "collapse" }}
          >
            <thead>
              <tr>
                <th style={{ border: "1px solid black", padding: "10px" }}>
                  Feedback Time
                </th>
                <th style={{ border: "1px solid black", padding: "10px" }}>
                  Feedback
                </th>
                <th style={{ border: "1px solid black", padding: "10px" }}>
                  Input Garment Image
                </th>
                <th style={{ border: "1px solid black", padding: "10px" }}>
                  Input Model Image
                </th>
                {/* <th style={{ border: "1px solid black", padding: "10px" }}>
                  Tmp Image
                </th> */}
                <th style={{ border: "1px solid black", padding: "10px" }}>
                  Output Image
                </th>
                <th style={{ border: "1px solid black", padding: "10px" }}>
                  Review
                </th>
              </tr>
            </thead>
            <tbody>
              {this.state.requestList.map((item, index) => (
                <tr key={index}>
                  <td
                    style={{
                      border: "1px solid black",
                      padding: "10px",
                      width: "100px",
                    }}
                  >
                    <button
                      onClick={() => {
                        const itemCopy = { ...item };
                        delete itemCopy.input_image_garment_url;
                        delete itemCopy.input_image_model_url;
                        delete itemCopy.output_images;
                        this.handleTimestampClick(itemCopy);
                      }}
                      style={{
                        background: "none",
                        border: "none",
                        color: "blue",
                        textDecoration: "underline",
                        cursor: "pointer",
                        padding: "0",
                        font: "inherit",
                      }}
                    >
                      {new Date(parseInt(item.timestamp, 10)).toLocaleString()}
                    </button>
                  </td>
                  <td
                    style={{
                      border: "1px solid black",
                      padding: "10px",
                      width: "200px",
                    }}
                  >
                    <pre
                      style={{
                        whiteSpace: "pre-wrap",
                        wordWrap: "break-word",
                        wordBreak: "break-all",
                      }}
                    >
                      {JSON.stringify(
                        {
                          user_comment: item.user_comment,
                          user_issue_type: item.user_issue_type,
                        },
                        null,
                        2
                      )}
                    </pre>
                  </td>
                  <td style={{ border: "1px solid black", padding: "10px" }}>
                    <img
                      src={item.input_image_garment_url}
                      alt="description"
                      title={item.input_image_garment_url}
                      style={{
                        maxWidth: "256px",
                        maxHeight: "256px",
                        height: "auto",
                        width: "auto",
                      }}
                      onClick={() =>
                        this.setSelectedImage(item.input_image_garment_url)
                      }
                    />
                  </td>
                  <td style={{ border: "1px solid black", padding: "10px" }}>
                    <img
                      src={item.input_image_model_url}
                      alt="description"
                      title={item.input_image_id}
                      style={{
                        maxWidth: "256px",
                        maxHeight: "256px",
                        height: "auto",
                        width: "auto",
                      }}
                      onClick={() =>
                        this.setSelectedImage(item.input_image_model_url)
                      }
                    />
                  </td>
                  {/* <td style={{ border: "1px solid black", padding: "10px" }}>
                    {item.tmp_images &&
                      item.tmp_images.map((image, index) => (
                        <img
                          key={index}
                          src={image.image_url}
                          alt="description"
                          title={image.image_id}
                          style={{
                            maxWidth: "256px",
                            maxHeight: "256px",
                            height: "auto",
                            width: "auto",
                          }}
                          onMouseEnter={() =>
                            this.setSelectedImage(image.image_url)
                          }
                          onMouseLeave={() => this.setSelectedImage(null)}
                        />
                      ))}
                  </td> */}
                  <td style={{ border: "1px solid black", padding: "10px" }}>
                    {item.output_images &&
                      item.output_images.map((image, index) => (
                        <img
                          key={index}
                          src={image.image_url}
                          alt="description"
                          title={image.image_id}
                          style={{
                            maxWidth: "256px",
                            maxHeight: "256px",
                            height: "auto",
                            width: "auto",
                          }}
                          onClick={() => this.setSelectedImage(image.image_url)}
                        />
                      ))}
                  </td>
                  <td style={{ border: "1px solid black", padding: "10px" }}>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "flex-start",
                      }}
                    >
                      <button
                        onClick={() => this.handleApproveClick(item)}
                        style={approveButtonStyle}
                      >
                        Approve
                      </button>
                      <button
                        onClick={() => this.handleRejectClick(item)}
                        style={rejectButtonStyle}
                      >
                        Reject
                      </button>
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        )}

        {this.state.selectedImage && (
          <div
            className="modal-overlay"
            style={modalOverlayStyle}
            onClick={(e) => {
              if (e.target === e.currentTarget) {
                this.closePopup();
              }
            }}
          >
            <div className="modal-content" style={modalContentStyle}>
              <img
                src={this.state.selectedImage}
                alt="Selected"
                style={{ maxHeight: "1024px", width: "auto" }}
              />
            </div>
          </div>
        )}

        {this.state.currentRequest && (
          <div className="modal-overlay" style={modalOverlayStyle}>
            <div className="modal-content" style={modalContentStyle}>
              <pre>{JSON.stringify(this.state.currentRequest, null, 2)}</pre>
              <button onClick={this.closePopup} style={closeButtonStyle}>
                Close
              </button>
            </div>
          </div>
        )}
      </div>
    );
  }
}

const modalOverlayStyle = {
  position: "fixed",
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  backgroundColor: "rgba(0, 0, 0, 0.5)",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
};

const modalContentStyle = {
  backgroundColor: "#fff",
  padding: "20px",
  borderRadius: "8px",
  textAlign: "center",
};

const closeButtonStyle = {
  marginTop: "10px",
  padding: "10px 20px",
  backgroundColor: "#007bff",
  color: "#fff",
  border: "none",
  borderRadius: "4px",
  cursor: "pointer",
};

const approveButtonStyle = {
  padding: "10px 20px",
  backgroundColor: "#007bff",
  color: "#fff",
  border: "none",
  borderRadius: "4px",
  cursor: "pointer",
  marginBottom: "10px", // Add margin to separate buttons
};

const rejectButtonStyle = {
  padding: "10px 20px",
  backgroundColor: "black", // Set the background color to black
  color: "#fff",
  border: "none",
  borderRadius: "4px",
  cursor: "pointer",
};

export default FeedbackReview;
