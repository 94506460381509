import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Box, Button, FormControl, Grid, InputLabel, MenuItem, Select } from '@mui/material';

const filterOptions = ['gender', 'view', 'body_pos', 'sleeve', 'length', 'tightness'];

function GarmentModelAnnotation() {
    const [formData, setFormData] = useState({
        gender: '',
        view: '',
        body_pos: '',
        sleeve: '',
        length: '',
        tightness: ''
    });
    const navigate = useNavigate();

    const handleChange = (event) => {
        const { name, value } = event.target;
        setFormData(prev => ({
            ...prev,
            [name]: value === "Yes" ? "na" : ""
        }));
    };

    const handleSearch = async () => {
        const params = new URLSearchParams(
            Object.entries(formData).filter(([_, value]) => value)
        );

        const response = await fetch(`https://kch9i3uzii.execute-api.us-west-2.amazonaws.com/prod/garments/models?${params.toString()}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'x-api-key': 'VbRtf2Iwie8CyMMRo7QVZ3mEzPhXgWim4sQ5heH8'
            }
        });

        if (response.ok) {
            const data = await response.json();
            navigate('/bulk_garment_model_patch', { state: { imageIds: data.map(item => item.id) } });
        } else {
            console.error("Failed to fetch search results:", await response.text());
        }
    };

    return (
        <Box>
            <Grid container spacing={2}>
                {filterOptions.map(key => (
                    <Grid item xs={12} sm={6} md={4} lg={2} key={key}>
                        <FormControl fullWidth>
                            <InputLabel>{key.replace('_', ' ')}</InputLabel>
                            <Select value={formData[key]} name={key} onChange={handleChange}>
                                <MenuItem value="Yes">Yes</MenuItem>
                                <MenuItem value="No">No</MenuItem>
                            </Select>
                        </FormControl>
                    </Grid>
                ))}
                <Grid item xs={12}>
                    <Button variant="contained" onClick={handleSearch}>Search</Button>
                </Grid>
            </Grid>
        </Box>
    );
}

export default GarmentModelAnnotation;
