import {Buffer} from 'buffer';

export class TokenHandler {
    constructor(token) {
        this.token = token;
        let body = JSON.parse(Buffer.from(token.split('.')[1], 'base64').toString());
        this.email = body['email'];
        this.phone_number = body['phone_number'];
        this.user_name = body['cognito:username'];
    }

    get_email() {
        return this.email;
    }

    get_phone_number() {
        return this.phone_number;
    }

    get_user_name() {
        return this.user_name;
    }

    authorization() {
        return 'Bearer ' + this.token;
    }



}