import React, { useState } from 'react';
import { Button, Box } from '@mui/material';
import JSZip from 'jszip';

function BulkGarmentModelCreate({ onImagesUploaded }) {
  const [file, setFile] = useState(null);

  // Utility function to convert Blob to Base64
  const toBase64 = file => new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result.split(',')[1]);  // remove the data URL part
    reader.onerror = error => reject(error);
  });

  // Handle file input change
  const handleFileChange = (event) => {
    setFile(event.target.files[0]);
  };

  // Handle file upload
  const handleUpload = async () => {
    if (!file) {
      alert("Please select a file first.");
      return;
    }

    try {
      const zip = new JSZip();
      const zipContents = await zip.loadAsync(file);
      const files = Object.keys(zipContents.files).filter(filename => 
        filename.endsWith('.png') || filename.endsWith('.jpg') || filename.endsWith('.jpeg')
      );

      const imageIds = [];
      for (const filename of files) {
        const fileData = await zipContents.files[filename].async("blob");
        const base64Image = await toBase64(fileData);

        const payload = [{
          data: base64Image,
          file_type: "png" // As the server expects this field
        }];

        const response = await fetch('https://kch9i3uzii.execute-api.us-west-2.amazonaws.com/prod/garments/models', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'x-api-key': 'VbRtf2Iwie8CyMMRo7QVZ3mEzPhXgWim4sQ5heH8' // Replace with your actual API key
          },
          body: JSON.stringify(payload)
        });
        if (response.ok) {
          console.log("Response Headers:", response.headers.get("Content-Type")); // Check if the content type is 'application/json'
          const text = await response.text(); // First read the response as text to debug
          console.log("Response Text:", text); // Log the text to see what's being returned
          try {
              const responseData = JSON.parse(text); // Parse the text as JSON
              imageIds.push(responseData[0].Id); // Assuming 'id' is the field where the ID is stored
          } catch (error) {
              console.error("Error parsing JSON:", error);
          }
        } else {
          console.error("Failed to upload image:", filename, await response.text());
        }
      }

      onImagesUploaded(imageIds);
    } catch (error) {
      console.error("Error uploading zip file:", error);
    }
  };

  return (
    <Box>
      <input type="file" onChange={handleFileChange} accept=".zip" />
      <Button variant="contained" onClick={handleUpload} disabled={!file}>Upload Zip</Button>
    </Box>
  );
}

export default BulkGarmentModelCreate;
