import * as React from 'react';

import Chip from '@mui/material/Chip';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import CasinoOutlinedIcon from '@mui/icons-material/CasinoOutlined';

import { createTheme, ThemeProvider, styled } from '@mui/material/styles';

import { PickerModel } from '../../models/stateful/pickerModel';
import LoadingBlock from '../../views/utils/loadingBlock';

const Item = styled(Paper)(({ theme }) => ({
    ...theme.typography.body2,
    textAlign: 'center',
    color: theme.palette.text.secondary,
    height: 30,
    lineHeight: '30px',
  }));

export default class Picker extends React.PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            topics: [],
            loading: true
        };
        this.pickerHandler = new PickerModel();
    }

    isSelected(topic) {
        if (this.props.selected == topic) {
            return true;
        } else {
            return false;
        }
    }

    componentDidMount() {
        const callback = (records) => {
            this.setState({ 
                topics: records,
                loading: false
            });
        }
        this.pickerHandler.list(this.props.keywords, 10, callback)
    }

    render() {

        const handleClick = (event) => {
            const topic = event.currentTarget.id;
            this.props.setSelected(topic)
        }

        const rollADice = () => {
            const topic = this.state.topics[Math.floor(Math.random()*this.state.topics.length)];
            this.props.setSelected(topic.TopicId);
        }

      if (this.state.loading) {
          return (<LoadingBlock />);
      } else {
          return (
            <Grid container spacing={2}>
          {this.state.topics.length !== 0 ? this.state.topics.map(topic=> {
          return ( 
            <Grid item xs={12} key={topic.Title} >
                <Grid container spacing={2}>
                <Grid item xs={2} />
            <Grid item xs={6} sx={{textAlign: 'left'}}>
            <Chip id={topic.TopicId} label={topic.Title} color="primary" variant={this.isSelected(topic.TopicId)?"filled":"outlined"} sx={{marginTop: '5px'}}
                  onClick={handleClick}/>
            </Grid>
            <Grid item xs={2}>
            <Item elevation={3}>{topic.Keyword}</Item></Grid>
            </Grid>
            </Grid>)
        }) : null}
            <Grid item xs={12}>
              <Button variant="contained" color="warning" startIcon={<CasinoOutlinedIcon />} onClick={rollADice}>Roll a dice!</Button>
            </Grid>
          </Grid>);
      }
    }
}

