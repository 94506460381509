import React from 'react';

import '../App.css';
import MainAppBar from './header';
import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';

import Tags from './demo/tags';
import Picker from './demo/picker';
import Articles from './demo/articles';

import Footer from './footer';
import Intro from './intro';
import Demo from './demo';

import Article from './article';
import ArticleView from './demo/articleView';
import TopicCollection from './demo/topicCollection';

const steps = ['Pick keywords (Maximum 5)', 'Pick a topic', 'Enjoy the magic'];



function HorizontalLinearStepper() {
  const [activeStep, setActiveStep] = React.useState(0);
  const [skipped, setSkipped] = React.useState(new Set());
  const [selected, setSelected] = React.useState([]);
  const [selectedTopic, setSelectedTopic] = React.useState('');

  const isStepOptional = (step) => {
    return false;
  };

  const isStepSkipped = (step) => {
    return skipped.has(step);
  };

  const handleNext = () => {
    let newSkipped = skipped;
    if (isStepSkipped(activeStep)) {
      newSkipped = new Set(newSkipped.values());
      newSkipped.delete(activeStep);
    }

    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setSkipped(newSkipped);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleSkip = () => {
    if (!isStepOptional(activeStep)) {
      // You probably want to guard against something like this,
      // it should never occur unless someone's actively trying to break something.
      throw new Error("You can't skip a step that isn't optional.");
    }

    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setSkipped((prevSkipped) => {
      const newSkipped = new Set(prevSkipped.values());
      newSkipped.add(activeStep);
      return newSkipped;
    });
  };

  const handleReset = () => {
    setActiveStep(0);
  };

  return (
    <Box sx={{ width: '100%', paddingTop: '30px', paddingLeft: '100px', paddingRight: '100px'}}>
      <Stepper activeStep={activeStep}>
        {steps.map((label, index) => {
          const stepProps = {};
          const labelProps = {};
          if (isStepOptional(index)) {
            labelProps.optional = (
              <Typography variant="caption">Optional</Typography>
            );
          }
          if (isStepSkipped(index)) {
            stepProps.completed = false;
          }
          return (
            <Step key={label} {...stepProps}>
              <StepLabel {...labelProps}>{label}</StepLabel>
            </Step>
          );
        })}
      </Stepper>
      {activeStep === steps.length ? (
        <React.Fragment>
          <Typography sx={{ mt: 2, mb: 1 }}>
            All steps completed - you&apos;re finished
          </Typography>
          <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
            <Box sx={{ flex: '1 1 auto' }} />
            <Button onClick={handleReset}>Reset</Button>
          </Box>
        </React.Fragment>
      ) : (
        <React.Fragment>
          <Typography sx={{ mt: 2, mb: 1 }}>Step {activeStep + 1}</Typography>
          {activeStep + 1 == 1 && <Tags selected={selected} setSelected={setSelected}/> }
          {activeStep + 1 == 2 && <Picker keywords={selected} selected={selectedTopic} setSelected={setSelectedTopic}/> }
          {activeStep + 1 == 3 && <Articles topicId={selectedTopic}/> }
          <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
            <Button
              color="inherit"
              disabled={activeStep === 0}
              onClick={handleBack}
              sx={{ mr: 1 }}
            >
              Back
            </Button>
            <Box sx={{ flex: '1 1 auto' }} />
            {isStepOptional(activeStep) && (
              <Button color="inherit" onClick={handleSkip} sx={{ mr: 1 }}>
                Skip
              </Button>
            )}

            <Button onClick={handleNext}>
              {activeStep === steps.length - 1 ? 'Finish' : 'Next'}
            </Button>
          </Box>
        </React.Fragment>
      )}
    </Box>
  );
}

/*
function Main() {
  return (
    <div className="App">
      <MainAppBar />
      <p/><p/>
      <Intro/>
      <Grid container alignItems="center" justifyContent="center" sx={{paddingTop: '50px'}}>
      <Grid item xs={0.6}></Grid><Grid item><Typography variant="h5">Try our demo to generate your 1<sup>st</sup> blog:</Typography></Grid>
      </Grid>
      <HorizontalLinearStepper />
      <Footer />
    </div>
  );
}
*/
function Main() {
  return (
    <div className="App">
      <MainAppBar />
      <p/><p/>
      <Intro/>
      <Footer />
    </div>
  );
}

/*
function Main() {
  return (
    <ArticleView topicId='ea680b47262e49e4b71f43b37c56a751' />
  );
}
*/
/*
function Main() {
  return (
    <Article topicId='ea680b47262e49e4b71f43b37c56a751' />
  );
}
*/
export default Main;