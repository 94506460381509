import React from "react";
import ReactDOM from "react-dom/client";
import { createBrowserRouter, RouterProvider } from "react-router-dom";

import "./fonts/FreeMono.ttf";
import "./fonts/FreeMonoBoldOblique.ttf";
import "./fonts/FreeMonoOblique.ttf";
import "./fonts/FreeSans.ttf";
import "./fonts/FreeSansBold.ttf";
import "./fonts/FreeSansBoldOblique.ttf";
import "./fonts/FreeSansOblique.ttf";
import "./fonts/FreeSerif.ttf";
import "./fonts/FreeSerifBold.ttf";
import "./fonts/FreeSerifBoldItalic.ttf";
import "./fonts/FreeSerifItalic.ttf";

import "./index.css";
import App from "./App";
import TopicsPortal from "./views/topicsPortal";
import PromptsPortal from "./views/promptsPortal";
import ChannelPortal from "./views/channelsPortal";
import PickAndPostPortal from "./views/pickAndPostPortal";
import Editor from "./views/articleEdit";
import reportWebVitals from "./reportWebVitals";
import ErrorPage from "./error-page";
import Main from "./main/main";
import Article from "./main/article";
import DragAndDrop from "./views/dragAndDrop";
import KolSelection from "./views/kolSelection";
import Sam from "./views/sam";
import GarmentModelCreate from "./views/garmentModelCreate";
import BulkGarmentModelManager from "./views/bulkGarmentModelManager";
import GarmentModelSearch from "./views/garmentModelSearch";
import SamModify from "./views/samModify";
import DressingJobsPortal from "./views/dressingJobsPortal";
import Benchmark from "./views/benchmark";
import InpaintingBenchmark from "./views/inpaintingBenchmark";
import QueryRequest from "./views/queryRequest";
import InviteCodes from "./views/inviteCodes";
import FeedbackReview from "./views/feedbackReview";
import FeedbackQuery from "./views/feedbackQuery";
import GarmentModelAnnotation from "./views/garmentModelAnnotation";
import BulkGarmentModelPatch from "./views/bulkGarmentModelPatch";

const router = createBrowserRouter([
  {
    path: "/",
    element: <Main />,
    errorElement: <ErrorPage />,
  },
  // {
  //   path: "/funconsole",
  //   element: <App portal=<PickAndPostPortal /> />,
  //   errorElement: <ErrorPage />,
  // },
  // {
  //   path: "/channels",
  //   element: <App portal=<ChannelPortal /> />,
  //   errorElement: <ErrorPage />,
  // },
  // {
  //   path: "/pick_and_post",
  //   element: <App portal=<PickAndPostPortal /> />,
  //   errorElement: <ErrorPage />,
  // },
  // {
  //   path: "/drag_and_drop",
  //   element: <App portal=<DragAndDrop /> />,
  //   errorElement: <ErrorPage />,
  // },
  // {
  //   path: "/sam",
  //   element: <App portal=<Sam /> />,
  //   errorElement: <ErrorPage />,
  // },
  {
    path: "/garment_model_create",
    element: <App portal=<GarmentModelCreate /> />,
    errorElement: <ErrorPage />,
  },
  {
    path: "/bulk_garment_model_label",
    element: <App portal=<BulkGarmentModelManager /> />,
    errorElement: <ErrorPage />,
  },
  {
    path: "/bulk_garment_model_patch",
    element: <App portal=<BulkGarmentModelPatch /> />,
    errorElement: <ErrorPage />,
  },
  {
    path: "/garment_model_search",
    element: <App portal=<GarmentModelSearch /> />,
    errorElement: <ErrorPage />,
  },
  {
    path: "/garment_model_annotation",
    element: <App portal=<GarmentModelAnnotation /> />,
    errorElement: <ErrorPage />,
  },
  // {
  //   path: "/t2i_benchmark",
  //   element: <App portal=<Benchmark /> />,
  //   errorElement: <ErrorPage />,
  // },
  // {
  //   path: "/inpainting_benchmark",
  //   element: <App portal=<InpaintingBenchmark /> />,
  //   errorElement: <ErrorPage />,
  // },
  {
    path: "/query_request",
    element: <App portal=<QueryRequest /> />,
    errorElement: <ErrorPage />,
  },
  {
    path: "/invite_code",
    element: <App portal={<InviteCodes website={"tryon"} />} />,
    errorElement: <ErrorPage />,
  },
  {
    path: "/feedback_review",
    element: <App portal={<FeedbackReview />} />,
    errorElement: <ErrorPage />,
  },
  {
    path: "/feedback_query",
    element: <App portal={<FeedbackQuery />} />,
    errorElement: <ErrorPage />,
  },
  // {
  //   path: "/invite_code_portrait",
  //   element: <App portal={<InviteCodes website={"portrait"} />} />,
  //   errorElement: <ErrorPage />,
  // },
  // {
  //   path: "/topics",
  //   element: <App portal=<TopicsPortal /> />,
  //   errorElement: <ErrorPage />,
  // },
  // {
  //   path: "/articleEdit",
  //   element: <App portal=<Editor /> />,
  //   errorElement: <ErrorPage />,
  // },
  // {
  //   path: "/article",
  //   element: <App portal=<Article /> />,
  //   errorElement: <ErrorPage />,
  // },
  // {
  //   path: "/prompts",
  //   element: <App portal=<PromptsPortal /> />,
  //   errorElement: <ErrorPage />,
  // },
  // {
  //   path: "/sam_modify",
  //   element: <App portal=<SamModify /> />,
  //   errorElement: <ErrorPage />,
  // },
  // {
  //   path: "/dressing_jobs",
  //   element: <App portal=<DressingJobsPortal /> />,
  //   errorElement: <ErrorPage />,
  // },
  // {
  //   path: "/kol_selection",
  //   element: <App portal=<KolSelection /> />,
  //   errorElement: <ErrorPage />,
  // },
]);

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
