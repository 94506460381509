import * as React from 'react';

import Chip from '@mui/material/Chip';
import Button from '@mui/material/Button';
import CasinoOutlinedIcon from '@mui/icons-material/CasinoOutlined';

import { TagsModel } from '../../models/stateful/tagsModel';


export default class Tags extends React.PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            tags: [],
            loading: true
        };
        this.tagsHandler = new TagsModel();
    }

    isSelected(keyword) {
        if (this.props.selected.indexOf(keyword) > -1) {
            return true;
        } else {
            return false;
        }
    }

    componentDidMount() {
        const callback = (records) => {
            this.setState({ 
                tags: records,
                loading: false
            });
        }
        this.tagsHandler.list(callback)
    }

    render() {

        const handleClick = (event) => {
            const keyword = event.currentTarget.id;
            if (!this.isSelected(keyword)) {
                this.props.setSelected(prevSelected => [...prevSelected, keyword])
            } else {
                var array = [...this.props.selected]; // make a separate copy of the array
                var index = array.indexOf(keyword)
                if (index !== -1) {
                    array.splice(index, 1);
                    this.props.setSelected(array);
                }
            }
        }

        const rollADice = () => {
            this.props.setSelected([this.state.tags[Math.floor(Math.random()*this.state.tags.length)]]);
        }

      if (this.state.loading) {
          return null;
      } else {
          return (
          <>
          {this.state.tags.length !== 0 ? this.state.tags.map(tag=> {
          return ( 
            <Chip key={tag} id={tag} label={tag} color="primary" variant={this.isSelected(tag)?"filled":"outlined"} sx={{marginRight: '5px', marginTop: '5px'}}
                  onClick={handleClick}
            />) 
        }) : null}
            <br/>
            <br/>
            <Button variant="contained" color="warning" startIcon={<CasinoOutlinedIcon />} onClick={rollADice}>Roll a dice!</Button>
          </>);
      }
    }
}

