import { createCommand } from "lexical";
import { COMMAND_PRIORITY_LOW } from "lexical";

export const TOOLBAR_CUSTOM_COMMAND = createCommand();

export const registerToolBarCommand = (editor) => {
    editor.registerCommand(
    TOOLBAR_CUSTOM_COMMAND,
    (payload) => {
      console.log(payload); // Hello World!
      
      if (payload.command === 'instagram') {
        payload.delete_toggle_func(true);
      }
      return true;
    },
    COMMAND_PRIORITY_LOW
  );
}