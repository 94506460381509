import React from "react";
import axios from "axios";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";

import { TokenHandler } from "../utils/token-handler.js";
import { get_access_token } from "../utils/authorization.js";

class InviteCodes extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      codes: [],
      currentInviteCode: null,
      currentCredits: "50",
      requestList: [],
      accessToken: "",
    };
  }

  componentDidMount() {
    const tokenCallbackFunc = (jwt) => {
      this.setState({ accessToken: jwt }, () => {
        this.tokenHandler = new TokenHandler(jwt);
        this.fetchAllInviteCodes(); // Move this inside setState callback
      });
    };
    get_access_token(tokenCallbackFunc);
    window.addEventListener("keydown", this.handleKeyPress);
  }

  componentWillUnmount() {
    window.removeEventListener("keydown", this.handleKeyPress);
  }

  handleKeyPress = (event) => {
    if (event.code === "Enter") {
      this.handleInviteCodeChange();
    }
  };

  handleInviteCodeChange = (event) => {
    this.setState({ currentInviteCode: event.target.value });
  };

  handleCreditsChange = (event) => {
    this.setState({ currentCredits: event.target.value });
  };

  handleUpdateInviteCodeChange = (item) => {
    this.setState({
      currentInviteCode: item.code_id,
      currentCredits: item.credits,
    });
  };

  fetchAllInviteCodes = () => {
    axios
      .get(
        "https://n7jwv35eki.execute-api.us-west-2.amazonaws.com/prod/internal_tool/invite_code",
        {
          params: { website: this.props.website },
          headers: {
            Authorization: `Bearer ${this.state.accessToken}`,
          },
        }
      )
      .then((response) => {
        this.setState({ codes: response.data });
      });
  };

  updateInviteCode = () => {
    axios
      .post(
        "https://n7jwv35eki.execute-api.us-west-2.amazonaws.com/prod/internal_tool/invite_code",
        {
          code_id: this.state.currentInviteCode,
          credits: this.state.currentCredits,
        },
        {
          params: { website: this.props.website },
          headers: {
            Authorization: `Bearer ${this.state.accessToken}`,
          },
        }
      )
      .then((response) => {
        this.setState({ codes: response.data });
      });
  };

  deleteInviteCode = (code) => {
    axios
      .delete(
        "https://n7jwv35eki.execute-api.us-west-2.amazonaws.com/prod/internal_tool/invite_code",
        {
          params: { code_id: code, website: this.props.website },
          headers: {
            Authorization: `Bearer ${this.state.accessToken}`,
          },
        }
      )
      .then((response) => {
        this.setState({ codes: response.data });
      });
  };

  render() {
    return (
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          marginTop: "50px",
        }}
      >
        <div style={{ marginBottom: "20px" }}>
          <TextField
            value={this.state.currentInviteCode}
            onChange={this.handleInviteCodeChange}
            size="small"
            style={{ marginRight: "10px" }}
            placeholder="Enter code id"
          />
          <TextField
            value={this.state.currentCredits}
            onChange={this.handleCreditsChange}
            size="small"
            style={{ marginRight: "10px" }}
            placeholder="Enter credits"
          />
          <Button
            size="small"
            onClick={this.updateInviteCode}
            variant="contained"
            color="primary"
            style={{ height: "fit-content" }}
          >
            Add/Update
          </Button>
        </div>
        <div>
          <table
            style={{ border: "1px solid black", borderCollapse: "collapse" }}
          >
            <thead>
              <tr>
                <th style={{ border: "1px solid black", padding: "10px" }}>
                  Invite Code
                </th>
                <th style={{ border: "1px solid black", padding: "10px" }}>
                  Credits
                </th>
                <th style={{ border: "1px solid black", padding: "10px" }}>
                  Operations
                </th>
              </tr>
            </thead>
            <tbody>
              {this.state.codes.map((item, index) => (
                <tr key={index}>
                  <td style={{ border: "1px solid black", padding: "10px" }}>
                    {item.code_id}
                  </td>
                  <td style={{ border: "1px solid black", padding: "10px" }}>
                    {item.credits}
                  </td>
                  <td style={{ border: "1px solid black", padding: "10px" }}>
                    <button
                      onClick={() => this.handleUpdateInviteCodeChange(item)}
                    >
                      Update
                    </button>
                    <button onClick={() => this.deleteInviteCode(item.code_id)}>
                      Delete
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    );
  }
}

export default InviteCodes;
