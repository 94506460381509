import React, { useState } from 'react';
import BulkGarmentModelCreate from './bulkGarmentModelCreate';
import BulkGarmentModelUpdate from './bulkGarmentModelUpdate';

function BulkGarmentModelManager() {
    const [imageIds, setImageIds] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);

    const handleImagesUploaded = (ids) => {
        setImageIds(ids);
        setCurrentPage(2); // Transition to page 2 after upload
    };

    return (
        <div>
            {currentPage === 1 ? (
                <BulkGarmentModelCreate onImagesUploaded={handleImagesUploaded} />
            ) : (
                <BulkGarmentModelUpdate imageIds={imageIds} />
            )}
        </div>
    );
}

export default BulkGarmentModelManager;
