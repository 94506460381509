import * as React from 'react';
import PortalContainer from './views/utils/portalContainer';
import {Authenticator} from '@aws-amplify/ui-react';

import {Amplify} from 'aws-amplify';
import {withAuthenticator} from '@aws-amplify/ui-react';
import '@aws-amplify/ui-react/styles.css';
import awsExports from './aws-exports';

Amplify.configure(awsExports);

function App({signOut, user, portal}) {
    return (
        <div>
            <Authenticator>
                {({signOut, user}) => (
                    <PortalContainer portal={portal} signOut={signOut} user={user}/>
                )}
            </Authenticator>
        </div>
    );
}

export default withAuthenticator(App);