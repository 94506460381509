
import * as React from 'react';

import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import SendIcon from '@mui/icons-material/Send';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { Link } from 'react-router-dom';


import Illustration from '../views/images/how_it_works.png';

export default function Intro(props) {

  return (
    <Grid container alignItems="center" justifyContent="center">
        <Grid item xs={11} sx={{paddingBottom: '50px'}}>
        <Grid container>
            <Grid item xs={12} sx={{paddingLeft: '20px', paddingTop: '50px'}}>
             <Typography variant="h3" align="center" style={{color: "#000000"}}>
                <strong>
                    Scalable AI-generated content producer
                </strong>
            </Typography>
            <br/>
            </Grid>
            <Grid item xs={12} sx={{paddingLeft: '20px'}}>
            <Typography variant="h6" align="center">
            Discover AI-powered content<br/>
            Revolutionizing social media's intent<br/>
            Our proprietary tech fuels our ascent<br/>
            As top-tier KOLs, our time is spent<br/>
            <br/>
            Streamlined automation sets us apart<br/>
            Embrace the future and make a smart start<br/>
            </Typography>
            </Grid>
            </Grid>
        </Grid>
        <Grid item xs={11} >
           <Button component={Link} to="https://forms.gle/jmb2ih8kJASThw4K9" 
           target="_blank"
           rel="noreferrer"
           variant="contained" color="error" size="large" startIcon={<SendIcon />} style={{ width: 700, height: 70, fontSize: 20 }}>
            Subscribe our updates for progress
            </Button>
        </Grid>

        <Grid item xs={11} sx={{paddingLeft: '20px', paddingTop: '50px', paddingBottom: '30px'}}>
        <Typography variant="h5" align="center" style={{color: "#000000"}}>How it works?</Typography>
        </Grid>

        <br/>
  <Grid item alignItems="center" justifyContent="center">
    <Box
            component="img"
            sx={{
              height: 440,
              display: { xs: 'none', md: 'flex' },
              mr: 1
            }}
            alt="How it works?"
            src={Illustration}
        />
    </Grid>
    </Grid>
  );
}
