import * as React from 'react';

import Cookies from 'universal-cookie';

import Chip from '@mui/material/Chip';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardMedia from "@mui/material/CardMedia";
import CardActionArea from '@mui/material/CardActionArea';

import DownloadIcon from '@mui/icons-material/Download';

import ThumbUpIcon from '@mui/icons-material/ThumbUp';
import ThumbDownIcon from '@mui/icons-material/ThumbDown';

import { IconButton } from "@mui/material";

import { createTheme, ThemeProvider, styled } from '@mui/material/styles';
import { VoteModel } from '../../models/stateful/voteModel';

import { CollectionModel } from '../../models/stateful/collectionModel';
import LoadingBlock from '../../views/utils/loadingBlock';


const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    width: '100%',
    color: theme.palette.text.secondary
  }));

const cookies = new Cookies();

if (cookies.get('huhuai-gallery-like') === undefined) {
    cookies.set('huhuai-gallery-like', new Array(), { path: '/' });
}

if (cookies.get('huhuai-gallery-dislike') === undefined) {
    cookies.set('huhuai-gallery-dislike', new Array(), { path: '/' });
}

export default class TopicCollection extends React.PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            loading: true
        };
        this.collectionModel = new CollectionModel();
    }

    componentDidMount() {
        const callback = (records) => {
            this.setState({
                loading: false
            });
            this.props.setTopics(records);
        }
        this.collectionModel.list(this.props.keyword, callback)
    }

    render() {

        var maxHeight = Math.ceil(this.props.topics.length / 4) * 560;
        
        const handleClick = (event) => {


            const callbackFunc = () => {
            };
            if (event.currentTarget.style.color !== 'red') {
                const id_parts = event.currentTarget.id.split('-');
                const topic_id = id_parts[0];
                const vote = (id_parts[1] === 'like') ? 1 : 0;
                let cntDivDom = document.getElementById(topic_id + '-' + id_parts[1]);
                var val = cntDivDom.innerText;
                cntDivDom.innerText=parseInt(val) + 1;
                event.currentTarget.style.color = 'red';
                let voteHandler = new VoteModel();
                voteHandler.inc(topic_id, vote, callbackFunc);

                var cur_list = cookies.get('huhuai-gallery-'+id_parts[1]);
                cur_list = cur_list + topic_id
                cookies.set('huhuai-gallery-'+id_parts[1], cur_list, { path: '/' });
            }

        }

        const getColor = (topicId, type) => {
            var arr = cookies.get('huhuai-gallery-'+type)
            if (arr.includes(topicId) ) {
                return 'red';
            }
            return 'black';
        }

      if (this.state.loading) {
          return (<LoadingBlock />);
      } else {
          
          return (
            <ImageList sx={{ height: maxHeight }} cols={4} gap={8} rowHeight='auto'>
          {this.props.topics.length !== 0 ? this.props.topics.map(topic=> {
          return ( 

        <ImageListItem key={topic.TopicId} >

         
          <Card>
          
            <Stack
              direction="column"
              justifyContent="center"
              alignItems="center"
              spacing={0}
            >

            <Item>
              <div style={{ position: "relative"}}>
              <CardActionArea href={"article?topic_id="+topic.TopicId} target="_blank">
                {topic.Video === '' && <CardMedia component="img" image={topic.Image} title={topic.TopicId} alt={topic.TopicId}/> }
                {topic.Video !== '' && <CardMedia component="video" image={topic.Video} controls title={topic.TopicId} alt={topic.TopicId}/> }
              </ CardActionArea>
              </div>
            </Item>
            <Item sx={{height: '7ch'}}>{topic.Title}</Item>
            


            <Item sx={{height: '6ch'}}>
                <Stack direction="row" justifyContent="center" alignItems="center">
                <IconButton id={topic.TopicId + '-like-btn'} style={{color: getColor(topic.TopicId, 'like')}} onClick={handleClick}><ThumbUpIcon /></IconButton> <div id={topic.TopicId + '-like'}>{topic.Like}</div>
            <IconButton id={topic.TopicId + '-dislike-btn'} style={{color: getColor(topic.TopicId, 'dislike')}} onClick={handleClick}><ThumbDownIcon /></IconButton> <div id={topic.TopicId + '-dislike'}>{topic.Dislike}</div>
            
                </Stack>
            </Item>
            
            </ Stack>
            

            
          </Card>

          
          </ImageListItem>
       
          )
        }) : null}
          </ ImageList>);
      }
    }
}

