import Auth from '@aws-amplify/auth';

export function get_access_token(callbackFunc) {
    /*
    let access_token = '';
    if (localStorage.getItem("tokens") || localStorage.getItem("tokens")) {
        var tokens = JSON.parse(localStorage.getItem("tokens"));
        access_token = tokens.id_token;
    }
    return access_token;
    */

    Auth.currentSession().then(res=>{
        let idToken = res.getIdToken();
        let jwt = idToken.getJwtToken();
            
        //You can print them to see the full objects
        //console.log(`myAccessToken: ${JSON.stringify(accessToken)}`)
        callbackFunc(jwt);
      })
    
}