/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-west-2",
    "aws_cognito_region": "us-west-2",
    "aws_user_pools_id": "us-west-2_M3PJb0qMX",
    "aws_user_pools_web_client_id": "3nts39rsqd55nfi8ibta5kcklb",
    "oauth": {
        "domain": "auth.huhu.ai",
        "scope": [
            "phone",
            "email",
            "openid",
            "profile",
            "aws.cognito.signin.user.admin"
        ],
        "redirectSignIn": "https://localhost:3000/",
        "redirectSignOut": "https://localhost:3000/",
        "responseType": "token"
    },
    "federationTarget": "COGNITO_USER_POOLS",
    "aws_cognito_username_attributes": [],
    "aws_cognito_signup_attributes": [
        "EMAIL", "company"
    ],
    "aws_cognito_mfa_configuration": "ON",
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 10,
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ]
};


export default awsmobile;
