import React from "react";
import axios from "axios";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";

import { TokenHandler } from "../utils/token-handler.js";
import { get_access_token } from "../utils/authorization.js";

class QueryRequest extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      currentService: "TryonSaas",
      currentUserId: "",
      currentSessionId: "",
      currentRequestId: "",
      requestList: [],
      accessToken: "",
      selectedImage: null,
    };
  }

  componentDidMount() {
    const tokenCallbackFunc = (jwt) => {
      this.setState({ accessToken: jwt }, () => {
        this.tokenHandler = new TokenHandler(jwt);
      });
    };
    get_access_token(tokenCallbackFunc);
    window.addEventListener("keydown", this.handleKeyPress);
  }

  componentWillUnmount() {
    window.removeEventListener("keydown", this.handleKeyPress);
  }

  handleKeyPress = (event) => {
    if (event.code === "Enter") {
      this.handleFetchUserSession();
    }
  };

  handleServiceChange = (event) => {
    this.setState({ currentService: event.target.value });
  };

  handleUserIdChange = (event) => {
    this.setState({ currentUserId: event.target.value });
  };

  handleSessionIdChange = (event) => {
    this.setState({ currentSessionId: event.target.value });
  };

  handleRequestIdChange = (event) => {
    this.setState({ currentRequestId: event.target.value });
  };

  handleFetchUserSession = () => {
    let params = {
      service: this.state.currentService,
      user_id: this.state.currentUserId,
    };

    if (this.state.currentSessionId) {
      params.session_id = this.state.currentSessionId;
    }

    if (this.state.currentRequestId) {
      params.request_id = this.state.currentRequestId;
    }

    axios
      .get(
        "https://n7jwv35eki.execute-api.us-west-2.amazonaws.com/prod/internal_tool/user_session",
        {
          params: params,
          headers: {
            Authorization: `Bearer ${this.state.accessToken}`,
          },
        }
      )
      .then((response) => {
        this.setState({ requestList: response.data });
      });
  };

  setSelectedImage = (image_url) => {
    this.setState({ selectedImage: image_url });
  };

  render() {
    return (
      <div style={{ marginTop: "50px" }}>
        <div>
          <Select
            value={this.state.currentService}
            onChange={this.handleServiceChange}
            size="small"
            style={{ marginRight: "10px" }}
          >
            <MenuItem value={"TryonSaas"}>TryonSaas</MenuItem>
            <MenuItem value={"TryonApi"}>TryonApi</MenuItem>
            <MenuItem value={"ModelGen"}>Model Generation</MenuItem>
          </Select>
          <TextField
            value={this.state.currentUserId}
            onChange={this.handleUserIdChange}
            size="small"
            style={{ marginRight: "10px" }}
            placeholder="Enter user ID"
          />
          {/* <TextField
            value={this.state.currentSessionId}
            onChange={this.handleSessionIdChange}
            size="small"
            style={{ marginRight: "10px" }}
            placeholder="Enter session ID"
          />
          <TextField
            value={this.state.currentRequestId}
            onChange={this.handleRequestIdChange}
            size="small"
            style={{ marginRight: "10px" }}
            placeholder="Enter request ID"
          /> */}
          <Button
            size="small"
            onClick={this.handleFetchUserSession}
            variant="contained"
            color="primary"
            style={{ height: "fit-content" }}
          >
            Fetch
          </Button>
        </div>
        {this.state.selectedImage && (
          <div
            style={{
              position: "fixed",
              top: "50%",
              left: "25%",
              transform: "translate(-50%, -50%)",
              zIndex: 1000,
            }}
          >
            <img
              src={this.state.selectedImage}
              alt="description"
              style={{
                maxWidth: "1024px",
                maxHeight: "1024px",
                height: "auto",
                width: "auto",
              }}
            />
          </div>
        )}

        {this.state.requestList.length > 0 && (
          <table
            style={{ border: "1px solid black", borderCollapse: "collapse" }}
          >
            <thead>
              <tr>
                <th style={{ border: "1px solid black", padding: "10px" }}>
                  Timestamp
                </th>
                <th style={{ border: "1px solid black", padding: "10px" }}>
                  User Project ID
                </th>
                <th style={{ border: "1px solid black", padding: "10px" }}>
                  Request ID
                </th>
                <th style={{ border: "1px solid black", padding: "10px" }}>
                  Request Status
                </th>
                <th style={{ border: "1px solid black", padding: "10px" }}>
                  Request Body
                </th>
                <th style={{ border: "1px solid black", padding: "10px" }}>
                  Input Garment Image
                </th>
                <th style={{ border: "1px solid black", padding: "10px" }}>
                  Input Model Image
                </th>
                {/* <th style={{ border: "1px solid black", padding: "10px" }}>
                  Tmp Image
                </th> */}
                <th style={{ border: "1px solid black", padding: "10px" }}>
                  Output Image
                </th>
              </tr>
            </thead>
            <tbody>
              {this.state.requestList.map((item, index) => (
                <tr key={index}>
                  <td style={{ border: "1px solid black", padding: "10px" }}>
                    {new Date(parseInt(item.timestamp, 10)).toLocaleString()}
                  </td>
                  <td style={{ border: "1px solid black", padding: "10px" }}>
                    {item.user_project_id}
                  </td>
                  <td style={{ border: "1px solid black", padding: "10px" }}>
                    {item.request_id}
                  </td>
                  <td style={{ border: "1px solid black", padding: "10px" }}>
                    {item.request_status}
                  </td>
                  <td style={{ border: "1px solid black", padding: "10px" }}>
                    <pre
                      style={{ whiteSpace: "pre-wrap", wordWrap: "break-word" }}
                    >
                      {JSON.stringify(item.request_body, null, 2)}
                    </pre>
                  </td>
                  <td style={{ border: "1px solid black", padding: "10px" }}>
                    <img
                      src={item.input_image_garment_url}
                      alt="description"
                      title={item.input_image_garment_url}
                      style={{
                        maxWidth: "256px",
                        maxHeight: "256px",
                        height: "auto",
                        width: "auto",
                      }}
                      onMouseEnter={() =>
                        this.setSelectedImage(item.input_image_garment_url)
                      }
                      onMouseLeave={() => this.setSelectedImage(null)}
                    />
                  </td>
                  <td style={{ border: "1px solid black", padding: "10px" }}>
                    <img
                      src={item.input_image_model_url}
                      alt="description"
                      title={item.input_image_id}
                      style={{
                        maxWidth: "256px",
                        maxHeight: "256px",
                        height: "auto",
                        width: "auto",
                      }}
                      onMouseEnter={() =>
                        this.setSelectedImage(item.input_image_model_url)
                      }
                      onMouseLeave={() => this.setSelectedImage(null)}
                    />
                  </td>
                  {/* <td style={{ border: "1px solid black", padding: "10px" }}>
                    {item.tmp_images &&
                      item.tmp_images.map((image, index) => (
                        <img
                          key={index}
                          src={image.image_url}
                          alt="description"
                          title={image.image_id}
                          style={{
                            maxWidth: "256px",
                            maxHeight: "256px",
                            height: "auto",
                            width: "auto",
                          }}
                          onMouseEnter={() =>
                            this.setSelectedImage(image.image_url)
                          }
                          onMouseLeave={() => this.setSelectedImage(null)}
                        />
                      ))}
                  </td> */}
                  <td style={{ border: "1px solid black", padding: "10px" }}>
                    {item.output_images &&
                      item.output_images.map((image, index) => (
                        <img
                          key={index}
                          src={image.image_url}
                          alt="description"
                          title={image.image_id}
                          style={{
                            maxWidth: "256px",
                            maxHeight: "256px",
                            height: "auto",
                            width: "auto",
                          }}
                          onMouseEnter={() =>
                            this.setSelectedImage(image.image_url)
                          }
                          onMouseLeave={() => this.setSelectedImage(null)}
                        />
                      ))}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        )}
      </div>
    );
  }
}

export default QueryRequest;
