import * as React from 'react';
import { useState, useEffect } from 'react'

import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import Link from '@mui/material/Link';

import LoadingBlock from '../../views/utils/loadingBlock';
import { ArticlesModel } from '../../models/stateful/articlesModel';
import { createTheme, ThemeProvider, styled } from '@mui/material/styles';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';

import ImageCard from './imageCard';


import {
    EmailShareButton,
    FacebookShareButton,
    TwitterShareButton,
    WhatsappShareButton,
  } from "react-share";

  import {
    EmailIcon,
    FacebookIcon,
    TwitterIcon,
    WhatsappIcon,
  } from "react-share";


import FadeAlert from '../../views/utils/fadeAlert';

const Title = styled(Paper)(({ theme }) => ({
    ...theme.typography.body2,
    textAlign: 'center',
    height: 100,
    marginTop: '30px',
    paddingTop: '15px',
    color: theme.palette.text.secondary,
  }));

const Content = styled(Paper)(({ theme }) => ({
    ...theme.typography.body2,
    textAlign: 'left',
    color: theme.palette.text.secondary,
  }));

function Copy2ClipboardTitle(props) {
    const copyTitle = () => {
        var title = document.getElementById(props.topicId + '-title').innerText;
        navigator.clipboard.writeText(title);
        props.setShowAlert(true);
    }
    return (<Grid container>
        <Grid item xs={11}>{props.component}</Grid>
        <Grid item xs={1} >
            <IconButton aria-label="content-copy" color="primary" onClick={copyTitle}>
                <ContentCopyIcon />
            </IconButton>
        </Grid>
    </Grid>);
}

function Copy2ClipboardContent(props) {
    const copyContent = () => {
        var content = document.getElementById(props.topicId + '-' + props.type).innerText;
        navigator.clipboard.writeText(content);
        props.setShowAlert(true);
    }
    const twitter_tags = ["aigc","ai","social_media","automation"];
    return (<Grid container>
        <Grid item xs={9.5} />
        <Grid item sx={{textAlign: 'right'}}>
            <IconButton aria-label="content-copy" color="primary" size='large' onClick={copyContent}>
                <ContentCopyIcon />
            </IconButton>
        </Grid>
        <Grid item sx={{textAlign: 'right', paddingTop: '10px', paddingRight: '10px'}}>
            <EmailShareButton url="https://huhu.ai"
                subject="I wrote a stunning blog with a few clicks on huhu.ai, try it out!"
                body="No idea what to ask ChatGPT? <br/>Feeling painful to copy-paste contents anywhere? <br/>
                      Waking up at midnight to post your masterpieces? <br/><br/>Here's what we are offering:<br/><br/>
                Millions of daily trending topics for you to roll dice to pick<br/>
                Handsfree content generation (text, images, and tags) powered by cutting-edge AI<br/>
                Schedule to post anytime while you enjoy sunshine at Hawaii<br/>
                APIs for all above<br/>"
                separator=' '
            >
                <EmailIcon size={32} round={true} />
            </EmailShareButton>
        </Grid>
        <Grid item sx={{textAlign: 'right', paddingTop: '10px', paddingRight: '10px'}}>
            <FacebookShareButton url="https://huhu.ai"
                hashtag="#aigc"
                quote="I wrote a stunning blog with a few clicks on huhu.ai, try it out!">
                <FacebookIcon size={32} round={true} />
            </FacebookShareButton>
        </Grid>
        <Grid item sx={{textAlign: 'right', paddingTop: '10px', paddingRight: '10px'}}>
            <TwitterShareButton url="https://huhu.ai" 
                title="I wrote a stunning blog with a few clicks on huhu.ai, try it out!"
                hashtags={twitter_tags} >
                <TwitterIcon size={32} round={true} />
            </TwitterShareButton>
        </Grid>
        <Grid item sx={{textAlign: 'right', paddingTop: '10px', paddingRight: '50px'}}>
            <WhatsappShareButton url="https://huhu.ai" 
              title="I wrote a stunning blog with a few clicks on huhu.ai, try it out!"
              separator=" "
            >
                <WhatsappIcon size={32} round={true} />
            </WhatsappShareButton>
        </Grid>
        <Grid item xs={6} sx={{textAlign: 'center', paddingLeft: '50px', paddingTop: '10px', paddingBottom: '10px'}}>

                <ImageCard topicId={props.topicId} />
        </Grid>
        <Grid item xs={6}>{props.component}</Grid>
    </Grid>);
}

function Copy2ClipboardMain(props) {
    const copyContent = () => {
        var content = document.getElementById(props.topicId + '-' + props.type).innerText;
        navigator.clipboard.writeText(content);
        props.setShowAlert(true);
    }
    return (<Grid container>
        <Grid item xs={11} />
        <Grid item sx={{textAlign: 'right'}}>
            <IconButton aria-label="content-copy" color="primary" size='large' onClick={copyContent}>
                <ContentCopyIcon />
            </IconButton>
        </Grid>
        <Grid item xs={12}>{props.component}</Grid>
    </Grid>);
}

export default class ArticleView extends React.PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            title: '',
            content: '',
            short_version: '',
            tags: '',
            loading: true,
            showAlert: false
        };
        this.articlesHandler = new ArticlesModel();
    }

    componentDidMount() {
        const callback = (response) => {
            const tag_list = response.Tags.split(',');
            this.setState({ 
                title: response.Title,
                content: response.Content + 
                '(The content is generated by <a href="https://huhu.ai" style="text-decoration:none;color:black;">https://huhu.ai</a>)',
                short_version: response.ShortVersion + ' (By <a href="https://huhu.ai" style="text-decoration:none;color:black;">https://huhu.ai</a>)',
                tags: response.Tags,
                loading: false
            });
        }
        this.articlesHandler.list(this.props.topicId, callback)
    }

    render() {

        const setShowAlert = (show) => {
            this.setState({
                showAlert: show
            })
        }

      if (this.state.loading) {
          return (<LoadingBlock />);
      } else {
          return (
          <Grid container alignItems="center" justifyContent="center">
            {this.state.showAlert ? (<Grid item xs={12}><FadeAlert children="Copied to your clipboard!" show={this.state.showAlert} setShow={setShowAlert}/></Grid>) : null}
            <Grid item sx={{marginTop: '20'}} xs={11}>

                <Title elevation={3}>
                    <Copy2ClipboardTitle topicId={this.props.topicId} setShowAlert={setShowAlert}
                      component=<div id={this.props.topicId+'-title'} style={{ marginLeft:"60px",  marginRight:"60px"}} dangerouslySetInnerHTML={{__html: '<h2>' + this.state.title + '</h2>'}} /> 
                    />
                </Title>
                
            </Grid>
            <Grid item xs={12} sx={{textAlign: 'center'}}><br/>Content (Less than 500 words, <Link href="https://forms.gle/jmb2ih8kJASThw4K9" underline="hover" target="_blank" rel="noreferrer">unlock more words to sign up waitinglist!</Link>)<br/><br/></Grid>
            <Grid item xs={11}>
                <Content elevation={3}>
                    <Copy2ClipboardContent type='content' topicId={this.props.topicId} setShowAlert={setShowAlert}
                    component=<div id={this.props.topicId+'-content'} style={{ marginLeft:"60px", marginRight:"60px", marginBottom:"60px", paddingBottom:"10px",fontSize:"16px" }} dangerouslySetInnerHTML={{__html: this.state.content}} /> />                    
                </Content>
            </Grid>
            <Grid item xs={12} sx={{textAlign: 'center'}}><br/>Short Version (Less than 140 words, good for Twitter!)<br/><br/></Grid>
            <Grid item xs={11}>
                <Content elevation={3}>
                    <Copy2ClipboardMain type='short' topicId={this.props.topicId} setShowAlert={setShowAlert}
                    component=<div id={this.props.topicId+'-short'} style={{ marginLeft:"60px", marginRight:"60px", marginBottom:"30px", paddingBottom:"10px",fontSize:"20px" }} dangerouslySetInnerHTML={{__html: this.state.short_version}} /> />                    
                </Content>
            </Grid>
            <Grid item xs={12} sx={{textAlign: 'center'}}><br/>Tags<br/><br/></Grid>
            <Grid item xs={11}>
                <Content elevation={3}>
                    <Copy2ClipboardMain type='tags' topicId={this.props.topicId} setShowAlert={setShowAlert}
                      component=<div 
                                  id={this.props.topicId+'-tags'} 
                                  style={{ marginLeft:"60px", marginRight:"60px", marginBottom:"30px", paddingBottom:"10px",fontSize:"20px" }} 
                                  dangerouslySetInnerHTML={{__html: this.state.tags}} 
                                />
                    />                    
                </Content>
            </Grid>
          </Grid>);
      }
    }
}

