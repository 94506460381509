import * as React from 'react';
import TopicLoadingBlock from '../../views/utils/topicLoadingBlock';
import ArticleView from './articleView';
import { ArticlesModel } from '../../models/stateful/articlesModel';


export default class Articles extends React.PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            progress: 0
        };
    }

    componentDidMount() {
        const callbackFunc = (response) => {
            console.log(response);
        };
        this.articlesHandler = new ArticlesModel();
        this.articlesHandler.create([this.props.topicId], callbackFunc);
    }

    render() {

        const setProgress = (progress) => {
            this.setState({
                progress: progress
            })
        }


      if (this.state.progress !== 100) {
          return (<TopicLoadingBlock topicId={this.props.topicId} progress={this.state.progress} setProgress={setProgress}/>);
      } else {
          return (
            <ArticleView topicId={this.props.topicId}/>
        );
      }
    }
}

