
import * as React from 'react';

import Container from '@mui/material/Container';
import AppBar from '@mui/material/AppBar';
import IconButton from '@mui/material/IconButton';
import { Link } from 'react-router-dom';
import Toolbar from '@mui/material/Toolbar';
import LinkedInIcon from '@mui/icons-material/LinkedIn';

export default function Footer(props) {

  return (
    <>
    <AppBar position="static" style={{ background: '#2E3B55' }}>
      <Container maxWidth="xl">
        <Toolbar disableGutters>
        Huhu.AI © 2023


        <IconButton component={Link} to="https://www.linkedin.com/company/huhu-ai-inc" 
           target="_blank" variant="contained" color="primary"
           rel="noreferrer" aria-label="linkedin" size="large">
          <LinkedInIcon fontSize="inherit" />
        </IconButton>

        </Toolbar>
      </Container>
    </AppBar>
    </>
  );
}
