import { useState, useEffect } from 'react'
import Alert from '@mui/material/Alert';
    
const FadeAlert = ({ variant, children, show, setShow }) => {

  // On componentDidMount set the timer
  useEffect(() => {
    const timeId = setTimeout(() => {
      // After 3 seconds set the show value to false
      setShow(false)
    }, 3000)

    return () => {
      clearTimeout(timeId)
    }
  }, []);

  // If show is false the component will return null and stop here
  if (!show) {
    return null;
  }

  // If show is true this will be returned
  return (
    <Alert severity={variant}>{children}</Alert>
  )
}

FadeAlert.defaultPros = {
  variant: 'info',
}

export default FadeAlert;