import React from 'react';

import '../App.css';
import { TokenHandler } from '../utils/token-handler';

import { InfluencersModel } from '../models/stateful/influencersModel';
import { get_access_token } from '../utils/authorization';

import InfluencerAutocompleteBar from './utils/influencerAutocompleteBar';
import LoadingBlock from './utils/loadingBlock';

import ImageList from '@mui/material/ImageList';
import CollectionCard from './collectionCard';

import dayjs from 'dayjs';


class PickAndPostPortal extends React.PureComponent {

    constructor(props) {
      super(props);
      this.state = {
          images: [],
          influencers: [],
          influencer_name: '',
          moderation: 'safe',
          resolution: '540x960',
          date: dayjs(),
          accessToken: '',
          loading: true
      };
    }

    refresh() {
        let influencerHandler = new InfluencersModel(this.tokenHandler);
    
        const callbackFunc = (raw_records) => {
          this.setState({
              influencers: raw_records.map(function(value) {
                return value.InfluencerName;
              }),
              loading: false
          });
        };
    
        influencerHandler.list('real_beauty_influencer', callbackFunc);
    }

    componentDidMount() {
        const tokenCallbackFunc = (jwt) => {
            this.setState({ 
                accessToken: jwt
            });
            this.tokenHandler = new TokenHandler(jwt);
            this.refresh();
        };
        get_access_token(tokenCallbackFunc);
    }
 
    render() {

      const setImages = (images) => {
        this.setState({
            images: images
        });
      }

      const setInfluencerName = (influencer_name) => {
        this.setState({
            influencer_name: influencer_name
        });
      }

      const setModeration = (moderation) => {
        this.setState({
            moderation: moderation
        });
      }

      const setResolution = (resolution) => {
        this.setState({
            resolution: resolution
        });
      }

      const setDate = (date) => {
        this.setState({
            date: date
        });
      }
      if (this.state.loading) {
        return (<LoadingBlock />);
      } else {
        var pieces = this.state.resolution.split('x');
        var width = pieces[0];
        var height = pieces[1];
        return (
            <div>
                <InfluencerAutocompleteBar 
                    tokenHandler={this.tokenHandler} influencers={this.state.influencers} setInfluencerName={setInfluencerName}
                    moderation={this.state.moderation} setModeration={setModeration}
                    resolution={this.state.resolution} setResolution={setResolution}
                    date={this.state.date} setDate={setDate} setImages={setImages}
                />
            { this.state.influencer_name !== '' ? (<ImageList cols={4} gap={8} rowHeight='auto'>
            {this.state.images.length !== 0 ? this.state.images.map(image_s3_key=> {
              return (
                <CollectionCard key={image_s3_key} s3_key={image_s3_key} width={width} height={height} tokenHandler={this.tokenHandler}/>
              )
            }) : null}
          </ ImageList>) : null }
            </div>
        );
      }
    }
}

export default PickAndPostPortal;