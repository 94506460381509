import * as React from 'react';
import Grid from '@mui/material/Grid'; // Grid version 1

import ResponsiveAppBar from './header.js';
import MenuListComposition from './menu.js';
import { ThemeProvider, createTheme } from "@mui/material/styles";

const theme = createTheme({
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          "&.Mui-disabled": {
            background: "#f3f3f3",
            color: "#dadada"
          }
        }
      }
    }
  }
});

const Item = ({ content, ...rest }) => {
  return (
    <Grid item {...rest}>
        {content}
    </Grid>
  );
};

function Console(props) {
  return (
    <Grid container spacing={2} sx={{height: '100vh', display: 'flex', justifyContent: 'center' }}>
          <Grid item xs="auto">
            <MenuListComposition/>
          </Grid>
          <Grid item xs={10}>
            {props.portal}
          </Grid>
        </Grid>
  )
}

function Body(props) {
  return (
    <Grid container direction="column" justifyContent="flex-start">
      <Item xs={12} content=<ResponsiveAppBar signOut={props.signOut} user={props.user} /> ></Item>
      <Item xs={12} content=<ThemeProvider theme={theme}><Console portal={props.portal} signOut={props.signOut}/></ThemeProvider> ></Item>    
    </Grid>
  );
}

function PortalContainer(props) {
  return (
    <div className="App">
      
      <header>
      <Body portal={props.portal} signOut={props.signOut} user={props.user}/>
      </header>
    </div>
  );
}

export default PortalContainer;
