import React from 'react';
import axios from 'axios';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import ArrowBack from '@mui/icons-material/ArrowBack';
import ArrowForward from '@mui/icons-material/ArrowForward';
import ThumbUp from '@mui/icons-material/ThumbUp';
import ThumbDown from '@mui/icons-material/ThumbDown';
import CircularProgress from '@mui/material/CircularProgress';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';

import { TokenHandler } from '../utils/token-handler';
import { get_access_token } from '../utils/authorization.js';

class Benchmark extends React.PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            testId: '',
            tests: [],
            requestList: [],
            currentTestPurpose: '',
            currentRequestIndex: 0,
            currentImage: '',
            accessToken: '',
            isLoadingImage: false
        }
    }

    componentDidMount() {
        const tokenCallbackFunc = (jwt) => {
            this.setState({ accessToken: jwt }, () => {
                this.tokenHandler = new TokenHandler(jwt);
                this.fetchAllTests(); // Move this inside setState callback
            });
        };
        get_access_token(tokenCallbackFunc);
        window.addEventListener('keydown', this.handleKeyPress);
    }

    componentWillUnmount() {
        window.removeEventListener('keydown', this.handleKeyPress);
    }

    handleKeyPress = (event) => {
        if (event.code === 'ArrowRight') {
            this.handleNavigation(1);
        } else if (event.code === 'ArrowLeft') {
            this.handleNavigation(-1);
        } else if (event.code === 'KeyA') {
            this.handleRating(1);
        } else if (event.code === 'KeyD') {
            this.handleRating(0);
        }
    }

    fetchAllTests = () => {
        axios.get('https://n7jwv35eki.execute-api.us-west-2.amazonaws.com/prod/benchmark/test', {
            headers: {
                Authorization: `Bearer ${this.state.accessToken}`
            }
        }).then(response => {
            this.setState({ tests: response.data });
        });
    }

    handleTestIdChange = (event) => {
        this.setState({ testId: event.target.value });
    }

    handleFetchTest = () => {
        const selectedTest = this.state.tests.find(test => test.TestId === this.state.testId);

        if (selectedTest) {
            this.setState({ currentTestPurpose: selectedTest.Purpose });
        }

        axios.get('https://n7jwv35eki.execute-api.us-west-2.amazonaws.com/prod/benchmark/test', {
            params: {
                test_id: this.state.testId
            },
            headers: {
                Authorization: `Bearer ${this.state.accessToken}`
            }
        }).then(response => {
            this.setState({ requestList: response.data });
            this.fetchImage(response.data[0].RequestId);
        });
    }

    fetchImage = (requestId) => {
        this.setState({ isLoadingImage: true });  // Set isLoadingImage to true when image loading begins
        axios.get('https://n7jwv35eki.execute-api.us-west-2.amazonaws.com/prod/benchmark/asset', {
            params: {
                test_id: this.state.testId,
                request_id: requestId,
            },
            headers: {
                Authorization: `Bearer ${this.state.accessToken}`
            }
        }).then(response => {
            this.setState({ currentImage: response.data, isLoadingImage: false });  // Set isLoadingImage to false when image has loaded
        });
    }

    handleNavigation = (direction) => {
        let newIndex = this.state.currentRequestIndex + direction;
        if (newIndex >= 0 && newIndex < this.state.requestList.length) {
            this.setState({ currentRequestIndex: newIndex });
            this.fetchImage(this.state.requestList[newIndex].RequestId);
        }
    }

    handleRating = (label) => {
        axios.post('https://n7jwv35eki.execute-api.us-west-2.amazonaws.com/prod/benchmark/request', [
            {
                request_id: this.state.requestList[this.state.currentRequestIndex].RequestId,
                label: label,
            }
        ], {
            headers: {
                Authorization: `Bearer ${this.state.accessToken}`
            }
        });

        let updatedList = [...this.state.requestList];
        updatedList.splice(this.state.currentRequestIndex, 1);

        this.setState({ requestList: updatedList }, () => this.handleNavigation(0));
    }

    render() {
        const isFirstImage = this.state.currentRequestIndex === 0;
        const isLastImage = this.state.currentRequestIndex === this.state.requestList.length - 1;
    
        return (
            <div style={{ marginTop: '50px' }}>
                <div>
                    <Select
                        value={this.state.testId}
                        onChange={this.handleTestIdChange}
                        displayEmpty
                        size="small"
                        style={{ marginRight: '10px' }}
                    >
                        <MenuItem value="" disabled>
                            Select a test
                        </MenuItem>
                        {this.state.tests.map(test => (
                            <MenuItem value={test.TestId} key={test.TestId}>
                                {test.TestId}
                            </MenuItem>
                        ))}
                    </Select>
                    <Button size="small" onClick={this.handleFetchTest} variant="contained" color="primary" style={{ height: 'fit-content' }}>Fetch Test</Button>
                </div>
                {this.state.requestList.length > 0 &&
                    <div>
                        <h3 style={{ fontWeight: 'bold', color: 'blue' }}>Purpose: {this.state.currentTestPurpose}</h3>
                        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                            {!isFirstImage && (
                                <IconButton onClick={() => this.handleNavigation(-1)}>
                                    <ArrowBack />
                                </IconButton>
                            )}
                            <div style={{ textAlign: 'center' }}>
                                <p>{this.state.requestList[this.state.currentRequestIndex].Prompt}</p>
                                {this.state.isLoadingImage ? (
                                    <CircularProgress />  // Show CircularProgress when image is loading
                                ) : (
                                    <img 
                                        src={`data:image/png;base64,${this.state.currentImage}`} 
                                        alt="Asset" 
                                        style={{ 
                                            margin: '0 10px', 
                                            maxWidth: '768px', 
                                            maxHeight: '768px',
                                            height: 'auto',
                                            width: 'auto'
                                        }} 
                                    />
                                )}
                            </div>
                            {!isLastImage && (
                                <IconButton onClick={() => this.handleNavigation(1)}>
                                    <ArrowForward />
                                </IconButton>
                            )}
                        </div>
                        <div style={{ display: 'flex', justifyContent: 'center', marginTop: '10px' }}>
                            <IconButton onClick={() => this.handleRating(1)}>
                                <ThumbUp color="primary" />
                            </IconButton>
                            <IconButton onClick={() => this.handleRating(0)}>
                                <ThumbDown color="secondary" />
                            </IconButton>
                        </div>
                    </div>
                }
            </div>
        );
    }
}

export default Benchmark;
