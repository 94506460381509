import * as React from "react";
import Paper from "@mui/material/Paper";
import MenuItem from "@mui/material/MenuItem";
import MenuList from "@mui/material/MenuList";
import Stack from "@mui/material/Stack";
import Divider from "@mui/material/Divider";
import Link from "@mui/material/Link";
import ArrowRightIcon from "@mui/icons-material/ArrowRight";

// function InfluencerMenuList(props) {
//   if (props.open) {
//     return (
//       <MenuList>
//         <MenuItem component={Link} href="/pick_and_post">
//           &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Pick and Post
//         </MenuItem>
//       </MenuList>
//     );
//   } else {
//     return null;
//   }
// }

// function BenchmarkMenuList(props) {
//   if (props.open) {
//     return (
//       <MenuList>
//         <MenuItem component={Link} href="/t2i_benchmark">
//           &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;t2i
//         </MenuItem>
//         <MenuItem component={Link} href="/inpainting_benchmark">
//           &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;inpainting
//         </MenuItem>
//       </MenuList>
//     );
//   } else {
//     return null;
//   }
// }

// function ChannelMenuList(props) {
//   if (props.open) {
//     return (
//       <MenuList>
//         <MenuItem component={Link} href="/channels">
//           &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Channel Creation
//         </MenuItem>
//       </MenuList>
//     );
//   } else {
//     return null;
//   }
// }

function DressingJobMenuList(props) {
  if (props.open) {
    return (
      <MenuList>
        {/* <MenuItem component={Link} href="/sam">
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Start a new job
        </MenuItem>
        <MenuItem component={Link} href="/drag_and_drop">
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Modify gesture
        </MenuItem>
        <MenuItem component={Link} href="/kol_selection">
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Modify model
        </MenuItem>
        <MenuItem component={Link} href="/dressing_jobs">
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;View jobs
        </MenuItem> */}
        <MenuItem component={Link} href="/garment_model_create">
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Create Model
        </MenuItem>
        <MenuItem component={Link} href="/bulk_garment_model_label">
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Bulk Create Model
        </MenuItem>
        <MenuItem component={Link} href="/garment_model_search">
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Search Model
        </MenuItem>
        <MenuItem component={Link} href="/garment_model_annotation">
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Annotate Model
        </MenuItem>
      </MenuList>
    );
  } else {
    return null;
  }
}

function InternalToolMenuList(props) {
  if (props.open) {
    return (
      <MenuList>
        <MenuItem component={Link} href="/feedback_review">
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;feedback review
        </MenuItem>
        <MenuItem component={Link} href="/feedback_query">
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;feedback query
        </MenuItem>
        <MenuItem component={Link} href="/query_request">
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;request detail
        </MenuItem>
        <MenuItem component={Link} href="/invite_code">
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;invite codes - Tryon
        </MenuItem>
      </MenuList>
    );
  } else {
    return null;
  }
}

export default function MenuListComposition() {
  const [open, setOpen] = React.useState(false);
  const anchorRef = React.useRef(null);
  const [influencerMenuOpen, setInfluencerMenuOpen] = React.useState(false);
  const [benchmarkMenuOpen, setBenchmarkMenuOpen] = React.useState(false);
  const [channelMenuOpen, setChannelMenuOpen] = React.useState(false);
  const [dressingJobMenuOpen, setDressingJobMenuOpen] = React.useState(false);
  const [internalToolMenuOpen, setInternalToolMenuOpen] = React.useState(false);

  const toggleInfluencerMenu = () => {
    setInfluencerMenuOpen(!influencerMenuOpen);
  };

  const toggleBenchmarkMenu = () => {
    setBenchmarkMenuOpen(!benchmarkMenuOpen);
  };

  const toggleChannelMenu = () => {
    setChannelMenuOpen(!channelMenuOpen);
  };

  const toggleDressingJobMenu = () => {
    setDressingJobMenuOpen(!dressingJobMenuOpen);
  };

  const toggleInternalToolMenu = () => {
    setInternalToolMenuOpen(!internalToolMenuOpen);
  };

  // return focus to the button when we transitioned from !open -> open
  const prevOpen = React.useRef(open);
  React.useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current.focus();
    }

    prevOpen.current = open;
  }, [open]);

  return (
    <Stack direction="row" spacing={3} sx={{ padding: 3 }}>
      <Paper>
        <MenuList>
          {/* <MenuItem onClick={toggleInfluencerMenu}>
            <ArrowRightIcon /> Influencers
          </MenuItem>
          <InfluencerMenuList open={influencerMenuOpen} />
          <MenuItem onClick={toggleBenchmarkMenu}>
            <ArrowRightIcon /> Model Benchmarks
          </MenuItem>
          <BenchmarkMenuList open={benchmarkMenuOpen} />
          <MenuItem onClick={toggleChannelMenu}><ArrowRightIcon /> Channels</MenuItem>
          <ChannelMenuList open={channelMenuOpen}/> */}
          <MenuItem onClick={toggleDressingJobMenu}>
            <ArrowRightIcon /> Dressing jobs
          </MenuItem>
          <DressingJobMenuList open={dressingJobMenuOpen} />
          <MenuItem onClick={toggleInternalToolMenu}>
            <ArrowRightIcon /> Internal Tools
          </MenuItem>
          <InternalToolMenuList open={internalToolMenuOpen} />
        </MenuList>
      </Paper>
    </Stack>
  );
}
