import * as React from 'react';

import Stack from '@mui/material/Stack';
import ImageListItem from '@mui/material/ImageListItem';
import Card from '@mui/material/Card';
import CardMedia from "@mui/material/CardMedia";
import CardActionArea from '@mui/material/CardActionArea';
import Paper from '@mui/material/Paper';
import { styled } from '@mui/material/styles';

import { CustomPlaceholder } from 'react-placeholder-image';
import { InfImageModel } from '../models/stateful/infImageModel';

const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    width: '100%',
    color: theme.palette.text.secondary
  }));



export default class CollectionCard extends React.PureComponent {
    constructor(props) {
      super(props);
      this.state = {
        image: null,
        loading: true
      };
    }

    componentDidMount() {
        let imagehandler = new InfImageModel(this.props.tokenHandler);
        const callbackFunc = (base64Image) => {
          this.setState({ 
              image: base64Image,
              loading: false
          })
        };
        imagehandler.list(this.props.s3_key, callbackFunc);
    }
  
    render() {
        
        if (this.state.loading || !this.state.image ) {
            return (<CustomPlaceholder width={this.props.width} height={this.props.height} />);
        } else {
            const pieces = this.props.s3_key.split("/");
            const image_id = pieces[pieces.length - 1];
            return (
                <ImageListItem key={image_id} >
                    <Card>
                    <Stack
                        direction="column"
                        justifyContent="center"
                        alignItems="center"
                        spacing={0}
                    >
                        <Item>
                        <div style={{ position: "relative"}}>
                            <CardActionArea href={"article?topic_id="+image_id} target="_blank">
                                <CardMedia component="img" image={"data:image/png;base64," + this.state.image} title={image_id} alt={image_id}/>
                            </ CardActionArea>
                        </div>
                        </Item>
                        <Item sx={{height: '7ch'}}>{image_id}</Item>
                    </ Stack>
                    </Card>
                </ImageListItem>
          )
        }
        }
  }