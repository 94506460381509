import * as React from 'react';
import { CustomPlaceholder } from 'react-placeholder-image';
import { AssetsModel } from '../../models/stateful/assetsModel';
import { getPngDimensions } from '../../utils/base64';
import { getResizedDim } from '../../utils/images';


export default class ImageBlock extends React.PureComponent {
    constructor(props) {
      super(props);
      this.state = {
        image: null,
        loading: true
      };
    }

    componentDidMount() {
        let assetshandler = new AssetsModel(this.props.tokenHandler);
        const callbackFunc = (base64Image) => {
          this.setState({ 
              image: base64Image,
              loading: false
          })
        };
  
        const errorCallbackFunc = () => {
          this.setState({ 
              image: null,
              loading: true
          })
        }
        assetshandler.load(this.props.assetId, this.props.fileType, callbackFunc, errorCallbackFunc, this.props.assetType);
    }
  
    render() {
        
        if (this.state.loading || !this.state.image ) {
            return (<CustomPlaceholder width='200' height='200' />);
        } else {
            const srcImg = "data:image/png;base64," + this.state.image;
            var dim = getPngDimensions(srcImg);
            var resizedDim = getResizedDim(dim.width, dim.height, 200, 200);
            
            return (<img width={resizedDim.width} height={resizedDim.height} id={this.props.assetId} src={srcImg}/>);
        }
        }
  }