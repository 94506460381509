import React, { useState } from 'react';
import { Box, Button, FormControl, InputLabel, MenuItem, Select, Snackbar, Alert } from '@mui/material';

function GarmentModelUpdate({ image, imageFile, onReset }) {
  console.log("Received in Page 2 - image:", image, "imageFile:", imageFile);
  const [gender, setGender] = useState('');
  const [view, setView] = useState('');
  const [bodyPos, setBodyPos] = useState('');
  const [sleeve, setSleeve] = useState('');
  const [length, setLength] = useState('');
  const [tightness, setTightness] = useState('');
  const [open, setOpen] = useState(false);

  const toBase64 = file => new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result.split(',')[1]);  // Remove the beginning of the data URL
    reader.onerror = error => reject(error);
  });

  const handleClose = () => {
    setOpen(false);
  };

  const handleSubmit = async () => {
    if (!gender || !view || !bodyPos || !sleeve || !length || !tightness) {
      setOpen(true);
      return;
    }

    const imageData = await toBase64(imageFile);  // Use the passed imageFile

    const payload = [{
      data: imageData,
      file_type: "png",
      gender: gender,
      view: view,
      body_pos: bodyPos,
      sleeve: sleeve,
      length: length,
      tightness: tightness,
      category: "Your Category"  // Define how you collect 'category' if needed
    }];

    const response = await fetch('https://kch9i3uzii.execute-api.us-west-2.amazonaws.com/prod/garments/models', {
      method: 'POST',
      headers: {
          'Content-Type': 'application/json',
          'x-api-key': 'VbRtf2Iwie8CyMMRo7QVZ3mEzPhXgWim4sQ5heH8'
      },
      body: JSON.stringify(payload)
    });

    if (response.status === 200) {
      alert('Submitted successfully!');
      onReset();
    } else {
      alert('Submission failed.');
    }
  };

  return (
    <Box sx={{ display: 'flex', gap: 2, flexDirection: 'column' }}>
      <Box component="img" src={image} sx={{ 
        maxWidth: '300px', 
        maxHeight: '300px',
        width: '100%',  // This ensures the image fills the container width-wise
        height: 'auto',  // This makes the height adjust automatically to maintain the aspect ratio
        objectFit: 'contain'  // Ensures the image is scaled to be contained within the element's box
      }} />

      <FormControl fullWidth>
        <InputLabel>Gender</InputLabel>
        <Select value={gender} label="Gender" onChange={(e) => setGender(e.target.value)}>
          <MenuItem value="male">Male</MenuItem>
          <MenuItem value="female">Female</MenuItem>
          <MenuItem value="unisex">Unisex</MenuItem>
        </Select>
      </FormControl>
      <FormControl fullWidth>
        <InputLabel>View</InputLabel>
        <Select value={view} label="View" onChange={(e) => setView(e.target.value)}>
          <MenuItem value="front">Front</MenuItem>
          <MenuItem value="back">Back</MenuItem>
          <MenuItem value="profile">Profile</MenuItem>
        </Select>
      </FormControl>
      <FormControl fullWidth>
        <InputLabel>Body Position</InputLabel>
        <Select value={bodyPos} label="Body Position" onChange={(e) => setBodyPos(e.target.value)}>
          <MenuItem value="upper">Upper</MenuItem>
          <MenuItem value="lower">Lower</MenuItem>
          <MenuItem value="full">Full</MenuItem>
        </Select>
      </FormControl>
      <FormControl fullWidth>
        <InputLabel>Sleeve</InputLabel>
        <Select value={sleeve} label="Sleeve" onChange={(e) => setSleeve(e.target.value)}>
          <MenuItem value="na">N/A</MenuItem>
          <MenuItem value="no">No Sleeve</MenuItem>
          <MenuItem value="short">Short</MenuItem>
          <MenuItem value="medium">Medium</MenuItem>
          <MenuItem value="long">Long</MenuItem>
        </Select>
      </FormControl>
      <FormControl fullWidth>
        <InputLabel>Length</InputLabel>
        <Select value={length} label="Length" onChange={(e) => setLength(e.target.value)}>
          <MenuItem value="no">No Length</MenuItem>
          <MenuItem value="short">Short</MenuItem>
          <MenuItem value="medium">Medium</MenuItem>
          <MenuItem value="long">Long</MenuItem>
        </Select>
      </FormControl>
      <FormControl fullWidth>
        <InputLabel>Tightness</InputLabel>
        <Select value={tightness} label="Tightness" onChange={(e) => setTightness(e.target.value)}>
          <MenuItem value="tight">Tight</MenuItem>
          <MenuItem value="loose">Loose</MenuItem>
        </Select>
      </FormControl>
      <Button variant="contained" onClick={handleSubmit}>Submit</Button>
      <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
        <Alert onClose={handleClose} severity="error" sx={{ width: '100%' }}>
          Please fill in all fields before submitting.
        </Alert>
      </Snackbar>
    </Box>
  );
}

export default GarmentModelUpdate;
