import React, { useState } from 'react';
import { Box, Typography } from '@mui/material';
import GarmentModelUpdate from './garmentModelUpdate';  // Assuming the correct import path

function GarmentModelCreate() {
  const [image, setImage] = useState(null);
  const [imageFile, setImageFile] = useState(null);  // Define the state for the image file
  const [page, setPage] = useState(1);

  const handleDragOver = (e) => {
    e.preventDefault();
  };

  const handleDrop = (e) => {
    e.preventDefault();
    const files = e.dataTransfer.files;
    if (files && files[0]) {
        setImage(URL.createObjectURL(files[0]));
        setImageFile(files[0]);  // Save the file object to state
        setPage(2);
    }
  };

  const handleReset = () => {
    setImage(null);
    setImageFile(null);
    setPage(1);
  };

  if (page === 1) {
    return (
      <Box onDragOver={handleDragOver} onDrop={handleDrop} sx={{ width: 300, height: 300, bgcolor: 'lightgrey', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
        <Typography>Drag and drop an image here</Typography>
      </Box>
    );
  } else if (page === 2 && image) {
    console.log("Passing to Page 2 - image:", image, "imageFile:", imageFile);
    return <GarmentModelUpdate image={image} imageFile={imageFile} onReset={handleReset} />;
  } else {
    return null;
  }
}

export default GarmentModelCreate;
