import React, {Component} from 'react';
import {Grid} from '@mui/material';
import './imageGallery.css';
import ImageList from "@mui/material/ImageList";
import ImageListItem from "@mui/material/ImageListItem";
import CardMedia from "@mui/material/CardMedia";


/*
const ImageGallery = ({ thumbnails, onThumbnailClick }) => {
  const handleMouseOver = (e, index) => {
    e.target.style.border = '3px solid red';
  };

  const handleMouseOut = (e, index) => {
    e.target.style.border = 'none';
  };

  const handleClick = (e, index) => {
    onThumbnailClick(thumbnails[index]);
  };


  return (
    <div className="image-gallery">
      <Grid container className="grid-container">
        {thumbnails &&
          thumbnails.map((item, index) => (
            <Grid item key={index} xs={1.1} className="grid-item">
              <div className="thumbnail-wrapper">
                <img
                  src={item}
                  className="thumbnail"
                  onMouseOver={(e) => handleMouseOver(e, index)}
                  onMouseOut={(e) => handleMouseOut(e, index)}
                  onClick={(e) => handleClick(e, index)}
                />
              </div>
            </Grid>
          ))}
      </Grid>
    </div>
  );
};
*/


async function fetchBase64ImageData(url, accessToken) {
    try {
        const headers = {};

        if (accessToken) {
            headers['Authorization'] = `Bearer ${accessToken}`;
            headers['Origin'] = 'anonymous';
        }

        const response = await fetch(url, {
            headers,
        });

        if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
        }

        const base64Data = await response.text();

        return base64Data;
    } catch (error) {
        console.error('Error fetching image data:', error);
        throw error;
    }
}


class ImageGallery extends Component {
    constructor(props) {
        super(props);
        this.state = {
            images: [],
            selectedIndex: null,
        };
    }

    componentDidMount() {
        this.fetchImages();
    }

    async fetchImages() {

        const fetchImage = async (url) => {
            const base64ImageString = await fetchBase64ImageData(url, this.props.accessToken);
            return `data:image/png;base64,${base64ImageString}`
        }

        if (this.props.accessToken === undefined) {
            this.setState({images: this.props.thumbnails});
            return;
        }

        const imagePromises = this.props.thumbnails.map((url) => fetchImage(url));
        const images = await Promise.all(imagePromises);

        this.setState({images: images});
    }

    render() {
        const {images, selectedIndex} = this.state;

        const handleClick = (e, index) => {
            if (selectedIndex === index) {
                this.setState({selectedIndex: null});
                this.props.onThumbnailClick(null, null);
            } else {
                this.setState({selectedIndex: index});
                this.props.onThumbnailClick(this.props.thumbnails[index], index);
            }
        };


        if (this.props.onDragStart) {
            return (
                <div>
                    <ImageList cols={4}>
                        {images &&
                            images.map((item, index) => (
                                <ImageListItem key={index}>
                                    <img
                                        key={index}
                                        alt=""
                                        src={item}
                                        draggable="true"
                                        onDragStart={this.props.onDragStart}
                                        style={{width: 150, height: 150, margin: "10px"}}
                                    />
                                </ImageListItem>
                            ))}
                    </ImageList>
                </div>
            );
        } else {

            return (
                <div className="image-gallery">
                    <Grid container className="grid-container">
                        {images &&
                            images.map((item, index) => (
                                <Grid item key={index} xs={1.1} className="grid-item">
                                    <div className="item-wrapper">
                                        <div className="thumbnail-wrapper">
                                            <img
                                                src={item}
                                                className={`thumbnail ${selectedIndex === index ? 'selected' : ''}`}
                                                onClick={(e) => handleClick(e, index)}
                                            />
                                        </div>
                                        {this.props.titles !== undefined &&
                                            this.props.titles.length !== 0 && (
                                                <div className="title-text">{this.props.titles[index]}</div>
                                            )}
                                    </div>
                                </Grid>
                            ))}
                    </Grid>
                </div>
            );
        }
    }
}


export default ImageGallery;
