import * as React from 'react';
import { DataGrid } from '@mui/x-data-grid';

import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid'; 
import LoopIcon from '@mui/icons-material/Loop';

import DeleteConfirmationDialog from './utils/deleteConfirmationDialog.js';
import PromptCreateDialog from './promptCreate.js';
import LoadingBlock from './utils/loadingBlock.js';

import { TokenHandler } from '../utils/token-handler.js';
import { PromptsModel } from '../models/stateful/promptsModel'

import { get_access_token } from '../utils/authorization.js';

function TopBar(props) {
  const [open, setOpen] = React.useState(false);
  const [openAlertDialog, setOpenAlertDialog] = React.useState(false);

  const handleDelete = () => {
    let promptsHandler = new PromptsModel(props.tokenHandler);
    promptsHandler.delete(props.selectedRows, setOpenAlertDialog, [false]);
  };


  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const deletePrompts = () => {
    setOpenAlertDialog(true);
    
  };

  return (
    <div>
      <div>
      <DeleteConfirmationDialog
        open={openAlertDialog}
        selectedRows={props.selectedRows}
        setOpen={setOpenAlertDialog}
        handleDelete={handleDelete}
        title="Comfirm to delete the prompts?"
        details="The deleted prompts will not be able to recover."
      />
      <Grid container spacing={2} sx={{padding: "30px"}}>
        <Grid item xs={9}></Grid>
        <Grid item ><Button variant="outlined" sx={{ height: 50 }} onClick={() => window.location.reload(false)} startIcon={<LoopIcon />}></Button></Grid>
        <Grid item ><Button variant="contained" sx={{ height: 50, width: 100}} onClick={handleClickOpen}>Add</Button></Grid>
        <Grid item ><Button variant="contained" disabled={props.deleteBtnDisabled} sx={{ height: 50, width: 100}} onClick={deletePrompts}>Delete</Button></Grid>
      </Grid>
      </div>
      <PromptCreateDialog tokenHandler={props.tokenHandler} open={open} handleClose={handleClose}/>
    </div>
    );
  }

const columns = [
  { field: 'Content', headerName: 'Content', width: 400 },
  { field: 'Description', headerName: 'Description', width: 400 },
  { field: 'CreatedTime', headerName: 'Created Time', width: 400,
    renderCell: (params) => {
      var utcSeconds = params.value;
      var d = new Date(0); // The 0 there is the key, which sets the date to the epoch
      d.setUTCSeconds(utcSeconds);
      return d.toISOString();
    }
  }
];

function PromptsPortalBlock(props) {
  const [selectedRows, setSelectedRows] = React.useState([]);
  const [deleteBtnDisabled, setDeleteBtnDisabled] = React.useState(true);

  return (
    <Grid container spacing={1} >
        <Grid item xs={12}>
            <TopBar
              tokenHandler={props.tokenHandler}
              deleteBtnDisabled={deleteBtnDisabled}
              prompts={props.prompts}
              setPrompts={props.setPrompts}
              selectedRows={selectedRows}
            />
        </Grid>
        <Grid item xs={12}>
    <div style={{ height: '80vh', width: '100%' }}>
      <DataGrid
        rows={props.prompts}
        columns={columns}
        pageSize={20}
        rowsPerPageOptions={[20]}
        checkboxSelection
        onSelectionModelChange={(ids) => {
          const selectedIDs = new Set(ids);
          const selectedRows = props.prompts.filter((row) =>
            selectedIDs.has(row.id),
          );

          setSelectedRows(selectedRows);
          if (selectedRows.length > 0) {
            setDeleteBtnDisabled(false);
          } else {
            setDeleteBtnDisabled(true);
          };
        }}
        sx={{ width: '100%', height: '100%' }}
      />
      </div>
      </Grid>
    
    </Grid>
  );
}

export default class PromptsPortal extends React.PureComponent {
  constructor(props) {
      super(props);
      this.state = {
        prompts: [],
        loading: true,
        accessToken: ''
      };
  }

  refresh() {
    let promptHandler = new PromptsModel(this.tokenHandler);

    const callbackFunc = (raw_records) => {
      this.setState({ 
          prompts: raw_records,
          loading: false,
      });
    };

    promptHandler.list(callbackFunc);
  }

  componentDidMount() {
      const tokenCallbackFunc = (jwt) => {
          this.setState({ 
              accessToken: jwt
          });
          this.tokenHandler = new TokenHandler(jwt);
          this.refresh();
      };
      get_access_token(tokenCallbackFunc);

  }

  render() {
    const setPrompts = (prompts) => {
        this.setState({ 
          prompts: prompts
        });
    };
    if (this.state.loading) {
      return (<LoadingBlock />);
    } else {
      return (<PromptsPortalBlock tokenHandler={this.tokenHandler} prompts={this.state.prompts} setPrompts={setPrompts} />);
    }

  }
}
