import * as React from 'react';
import { DataGrid } from '@mui/x-data-grid';

import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid'; 
import Link from '@mui/material/Link';
import LoopIcon from '@mui/icons-material/Loop';

import DeleteConfirmationDialog from './utils/deleteConfirmationDialog.js';
import DatagridSearchBar from './utils/datagridSearchBar.js';
import LoadingBlock from './utils/loadingBlock.js';
import TopicsCreateDialog from './topics-create.js';
import { TokenHandler } from '../utils/token-handler.js';
import { TopicsModel } from '../models/stateful/topicsModel.js'
import { get_access_token } from '../utils/authorization.js';


function SearchBar(props) {
  const [open, setOpen] = React.useState(false);
  const [openAlertDialog, setOpenAlertDialog] = React.useState(false);

  const handleDelete = () => {
    let topicsHandler = new TopicsModel(props.tokenHandler);
    topicsHandler.delete(props.selectedRows, setOpenAlertDialog, [false]);
  };


  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const deleteTopics = () => {
    setOpenAlertDialog(true);
    
  };

  const refresh = () => {
      let topicsHandler = new TopicsModel(props.tokenHandler);
      const callbackFunc = (raw_records) => {
        props.setTopics(raw_records);
      };
      topicsHandler.list(callbackFunc, false);
  }

  return (
    <div>
      <div>
      <DeleteConfirmationDialog
        open={openAlertDialog}
        selectedRows={props.selectedRows}
        setOpen={setOpenAlertDialog}
        handleDelete={handleDelete}
        title="Comfirm to delete the topics?"
        details="The deleted topics will not be able to recover."
      />
      <Grid container spacing={2} sx={{padding: "30px"}}>
        <Grid item xs={6} sx={{ width: 600, height: 60 }}>
          <DatagridSearchBar
            fullRecords={props.topics}
            setFullRecordsFunc={props.setTopics}
            showKey="Title"
            disableTableRefreshFunc={props.setUseSearchResult}
          />
        </Grid>
        <Grid item xs={3}></Grid>
        <Grid item ><Button variant="outlined" sx={{ height: 50 }} onClick={refresh} startIcon={<LoopIcon />}></Button></Grid>
        <Grid item ><Button variant="contained" sx={{ height: 50, width: 100}} onClick={handleClickOpen}>Add</Button></Grid>
        <Grid item ><Button variant="contained" disabled={props.deleteBtnDisabled} sx={{ height: 50, width: 100}} onClick={deleteTopics}>Delete</Button></Grid>
      </Grid>
      </div>
      <TopicsCreateDialog tokenHandler={props.tokenHandler} open={open} handleClose={handleClose}/>
    </div>
    );
  }

const columns = [
  { field: 'Title', headerName: 'Title', width: 400,
    renderCell: (params) => {
      if (params.row.ProcessingStatus === 'PUBLISHED' || params.row.ProcessingStatus === 'IMAGES_READY_FOR_PUBLISH') {
        const url = "articleEdit/?article_id=" + params.row.TopicId;
        return (<Link href={url} >{params.value}</Link>);
      }
      return params.value;
    }
  },
  { field: 'ProcessingStatus', headerName: 'Status', width: 300 },
  { field: 'Template', headerName: 'Template', width: 300 },
  { field: 'ChannelTypeAndAccountIdAndCategory', headerName: 'Channels', width: 160 },
  { field: 'CreatedTime', headerName: 'Created Time', width: 160,
    renderCell: (params) => {
      var utcSeconds = params.value;
      var d = new Date(0); // The 0 there is the key, which sets the date to the epoch
      d.setUTCSeconds(utcSeconds);
      return d.toLocaleString();
    }
  }
];

function TopicsPortalBlock(props) {
  const [selectedRows, setSelectedRows] = React.useState([]);
  const [deleteBtnDisabled, setDeleteBtnDisabled] = React.useState(true);
  return (
    <Grid container spacing={1} >
        <Grid item xs={12}>
            <SearchBar
              tokenHandler={props.tokenHandler}
              setUseSearchResult={props.setUseSearchResult}
              deleteBtnDisabled={deleteBtnDisabled}
              topics={props.topics}
              setTopics={props.setTopics}
              selectedRows={selectedRows}
              refreshFunc={props.refreshFunc}
            />
        </Grid>
        <Grid item xs={12}>
    <div style={{ height: '80vh', width: '100%' }}>
      <DataGrid
        rows={props.topics}
        columns={columns}
        pageSize={20}
        rowsPerPageOptions={[20]}
        checkboxSelection
        initialState={{
          sorting: {
            sortModel: [{ field: 'CreatedTime', sort: 'desc' }],
          },
        }}
        onSelectionModelChange={(ids) => {
          const selectedIDs = new Set(ids);
          const selectedRows = props.topics.filter((row) =>
            selectedIDs.has(row.id),
          );

          setSelectedRows(selectedRows);
          if (selectedRows.length > 0) {
            setDeleteBtnDisabled(false);
          } else {
            setDeleteBtnDisabled(true);
          };
        }}
        sx={{ width: '100%', height: '100%' }}
      />
      </div>
      </Grid>
    
    </Grid>
  );
}

export default class TopicsPortal extends React.PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            topics: [],
            useSearchResult: false,
            loading: true,
            accessToken: ''
        };
    }

    refresh() {
      let topicsHandler = new TopicsModel(this.tokenHandler);

      const callbackFunc = (raw_records) => {
        this.setState({ 
            topics: raw_records,
            loading: false,
        });
      };

      if (!this.state.useSearchResult) {
          topicsHandler.list(callbackFunc);
      }
    }

    componentDidMount() {
        const tokenCallbackFunc = (jwt) => {
            this.setState({ 
                accessToken: jwt
            });
            this.tokenHandler = new TokenHandler(jwt);
            this.refresh();
        };
        get_access_token(tokenCallbackFunc);

    }

    render() {
      const setTopics = (topics) => {
          this.setState({ 
            topics: topics
          });
      };
      const setUseSearchResult = (useSearchResult) => {
        this.setState({ 
          useSearchResult: useSearchResult
        });
      };
      if (this.state.loading) {
          return (<LoadingBlock />);
      } else {
          return (
          <TopicsPortalBlock
            tokenHandler={this.tokenHandler}
            topics={this.state.topics}
            setTopics={setTopics}
            setUseSearchResult={setUseSearchResult}
            refreshFunc={this.refresh}
          />);
      }
    }
}

