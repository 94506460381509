import React, { useState } from 'react';
import { Box, Button, FormControl, Grid, InputLabel, MenuItem, Select, Typography } from '@mui/material';

const dropdownOptions = {
    gender: [
        { value: 'male', label: 'Male' },
        { value: 'female', label: 'Female' },
        { value: 'unisex', label: 'Unisex' }
    ],
    view: [
        { value: 'front', label: 'Front' },
        { value: 'back', label: 'Back' },
        { value: 'profile', label: 'Profile' }
    ],
    body_pos: [
        { value: 'upper', label: 'Upper' },
        { value: 'lower', label: 'Lower' },
        { value: 'full', label: 'Full' }
    ],
    sleeve: [
        { value: 'no', label: 'No Sleeve' },
        { value: 'short', label: 'Short' },
        { value: 'medium', label: 'Medium' },
        { value: 'long', label: 'Long' }
    ],
    length: [
        { value: 'no', label: 'No Length' },
        { value: 'short', label: 'Short' },
        { value: 'medium', label: 'Medium' },
        { value: 'long', label: 'Long' }
    ],
    tightness: [
        { value: 'tight', label: 'Tight' },
        { value: 'loose', label: 'Loose' }
    ]
};

function GarmentModelSearch() {
    const initialFormData = {
        gender: '',
        view: '',
        body_pos: '',
        sleeve: '',
        length: '',
        tightness: ''
    };
    const [formData, setFormData] = useState(initialFormData);
    const [searchResults, setSearchResults] = useState([]);

    const handleChange = (event) => {
        const { name, value } = event.target;
        setFormData(prev => ({ ...prev, [name]: value }));
    };

    const handleSearch = async () => {
        const params = new URLSearchParams();

        // Add non-empty search criteria as query parameters
        for (const [key, value] of Object.entries(formData)) {
            if (value) {
                params.append(`${key}`, value);
            }
        }

        const response = await fetch(`https://kch9i3uzii.execute-api.us-west-2.amazonaws.com/prod/garments/models/?${params.toString()}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'x-api-key': 'VbRtf2Iwie8CyMMRo7QVZ3mEzPhXgWim4sQ5heH8'
            }
        });

        if (response.ok) {
            const data = await response.json();
            setSearchResults(data);
        } else {
            console.error("Failed to fetch search results:", await response.text());
        }
    };

    // Function to reset the search form
    const handleClear = () => {
        setFormData(initialFormData);
        setSearchResults([]);
    };

    return (
        <Box>
            <Grid container spacing={2}>
                {Object.entries(dropdownOptions).map(([key, options]) => (
                    <Grid item xs={12} sm={6} md={4} lg={2} key={key}>
                        <FormControl fullWidth>
                            <InputLabel>{key.replace('_', ' ')}</InputLabel>
                            <Select value={formData[key]} name={key} label={key.replace('_', ' ')} onChange={handleChange}>
                                {options.map(option => (
                                    <MenuItem key={option.value} value={option.value}>{option.label}</MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Grid>
                ))}
                <Grid item xs={6}>
                    <Button variant="contained" onClick={handleSearch}>Search</Button>
                </Grid>
                <Grid item xs={6}>
                    <Button variant="outlined" color="secondary" onClick={handleClear}>Clear Selections</Button>
                </Grid>
            </Grid>

            {/* Display search results in a responsive grid layout */}
            <Grid container spacing={2} mt={2}>
                {searchResults.map(result => (
                    <Grid item xs={6} sm={4} md={2} key={result.id}>
                        <Box display="flex" flexDirection="column" alignItems="center">
                            <img src={result.url} alt="Garment" style={{ width: '100%', height: 'auto', objectFit: 'contain' }} />
                            <Typography>{result.id}</Typography>
                        </Box>
                    </Grid>
                ))}
            </Grid>
        </Box>
    );
}

export default GarmentModelSearch;
