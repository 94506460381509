import React, {useState} from 'react';
import '../App.css';
import {TokenHandler} from '../utils/token-handler';
import {get_access_token} from '../utils/authorization';
import LoadingBlock from './utils/loadingBlock';
import ImageList from '@mui/material/ImageList';
import {DressingJobsModel} from "../models/stateful/dressingJobsModel";
import DressingJobsAutocompleteBar from "./utils/dressingJobsAutocompleteBar";
import ImageListItem from "@mui/material/ImageListItem";
import CardMedia from "@mui/material/CardMedia";

class DressingJobsPortal extends React.PureComponent {

    constructor(props) {
        super(props);
        this.state = {
            images: [],
            jobs: [],
            job_id: '',
            accessToken: '',
            loading: true
        };
    }

    refresh() {
        let dressingJobsHandler = new DressingJobsModel(this.tokenHandler);

        const callbackFunc = (raw_records) => {
            this.setState({
                jobs: raw_records.map(function (value) {
                    return value;
                }),
                loading: false
            });
        };

        dressingJobsHandler.list(callbackFunc);
    }

    componentDidMount() {
        const tokenCallbackFunc = (jwt) => {
            this.setState({
                accessToken: jwt
            });
            this.tokenHandler = new TokenHandler(jwt);
            this.refresh();
        };
        get_access_token(tokenCallbackFunc);
        this.timer = setInterval(() => {
            // code to refresh your component.
            this.refresh();
        }, 30000)
    }

    componentWillUnmount() {
        clearInterval(this.timer);
    }

    render() {
        const setImages = (images) => {
            this.setState({
                images: images
            });
        }
        const setDressingJobId = (job_id) => {
            this.setState({
                job_id: job_id
            });
        }

        if (this.state.loading) {
            return (<LoadingBlock/>);
        } else {
            return (
                <div>
                    <DressingJobsAutocompleteBar
                        tokenHandler={this.tokenHandler} jobs={this.state.jobs} setDressingJobId={setDressingJobId}
                        setImages={setImages} job_id={this.state.job_id ? this.state.job_id !== '' : ''}
                    />
                    {(this.state.job_id !== '' && this.state.images.length !== 0) ? (
                        <ImageList cols={2}>
                            {this.state.images.map((url, index) => (
                                <ImageListItem key={index}>
                                    <img
                                        src={url}
                                        alt=""
                                    />
                                </ImageListItem>
                            ))}
                        </ImageList>) : null}
                </div>
            );
        }
    }
}

export default DressingJobsPortal;