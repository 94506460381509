import React, { useState, useEffect } from 'react';
import { Box, Button, FormControl, InputLabel, MenuItem, Select, Typography, Grid } from '@mui/material';

// Define the dropdown options for each attribute
const dropdownOptions = {
    gender: [
        { value: 'male', label: 'Male' },
        { value: 'female', label: 'Female' },
        { value: 'unisex', label: 'Unisex' }
    ],
    view: [
        { value: 'front', label: 'Front' },
        { value: 'back', label: 'Back' },
        { value: 'profile', label: 'Profile' }
    ],
    body_pos: [
        { value: 'upper', label: 'Upper' },
        { value: 'lower', label: 'Lower' },
        { value: 'full', label: 'Full' }
    ],
    sleeve: [
        { value: 'na', label: 'N/A' },
        { value: 'no', label: 'No Sleeve' },
        { value: 'short', label: 'Short' },
        { value: 'medium', label: 'Medium' },
        { value: 'long', label: 'Long' }
    ],
    length: [
        { value: 'no', label: 'No Length' },
        { value: 'short', label: 'Short' },
        { value: 'medium', label: 'Medium' },
        { value: 'long', label: 'Long' }
    ],
    tightness: [
        { value: 'tight', label: 'Tight' },
        { value: 'loose', label: 'Loose' }
    ]
};

function BulkGarmentModelUpdate({ imageIds }) {
    const [currentImageIndex, setCurrentImageIndex] = useState(0);
    const [currentImage, setCurrentImage] = useState(null);
    const [formData, setFormData] = useState({
        gender: '',
        view: '',
        body_pos: '',
        sleeve: '',
        length: '',
        tightness: ''
    });

    useEffect(() => {
        const fetchImage = async () => {
            const imageId = imageIds[currentImageIndex];
            const response = await fetch(`https://kch9i3uzii.execute-api.us-west-2.amazonaws.com/prod/garments/models/${imageId}`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'x-api-key': 'VbRtf2Iwie8CyMMRo7QVZ3mEzPhXgWim4sQ5heH8'  // Replace with your actual API key
                }
            });
            if (response.ok) {
                const data = await response.json();
                setCurrentImage(data.url);
            } else {
                console.error("Failed to fetch image data:", await response.text());
            }
        };

        fetchImage();
    }, [currentImageIndex, imageIds]);

    const handleChange = (event) => {
        const { name, value } = event.target;
        setFormData(prev => ({ ...prev, [name]: value }));
    };

    const handleSubmit = async () => {
        const imageId = imageIds[currentImageIndex];
        const response = await fetch(`https://kch9i3uzii.execute-api.us-west-2.amazonaws.com/prod/garments/models/${imageId}`, {
            method: 'PATCH',
            headers: {
                'Content-Type': 'application/json',
                'x-api-key': 'VbRtf2Iwie8CyMMRo7QVZ3mEzPhXgWim4sQ5heH8'
            },
            body: JSON.stringify(formData)
        });
        if (response.ok) {
            setCurrentImageIndex(prev => prev + 1);
            setFormData({ gender: '', view: '', body_pos: '', sleeve: '', length: '', tightness: '' });
        } else {
            console.error("Failed to update image data:", await response.text());
        }
    };

    if (currentImageIndex >= imageIds.length) {
        return <Typography>All images have been labeled.</Typography>;
    }

    return (
        <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
                <img src={currentImage} alt="Garment" style={{ width: '100%', height: 'auto', objectFit: 'contain' }} />
            </Grid>
            <Grid item xs={12} md={6}>
                {Object.entries(dropdownOptions).map(([key, options]) => (
                    <FormControl fullWidth key={key}>
                        <InputLabel>{key.replace('_', ' ')}</InputLabel>
                        <Select value={formData[key]} name={key} label={key.replace('_', ' ')} onChange={handleChange}>
                            {options.map(option => (
                                <MenuItem key={option.value} value={option.value}>{option.label}</MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                ))}
                <Button onClick={handleSubmit}>Submit</Button>
            </Grid>
        </Grid>
    );
}

export default BulkGarmentModelUpdate;
