import * as React from 'react';
import { DataGrid } from '@mui/x-data-grid';

import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import ListItem from '@mui/material/ListItem';
import List from '@mui/material/List';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import CloseIcon from '@mui/icons-material/Close';
import Slide from '@mui/material/Slide';

import ErrorAlertDialog from './utils/errorAlertDialog.js';
import LoadingBlock from './utils/loadingBlock.js';
import { TopicsModel } from '../models/stateful/topicsModel.js'
import { OperatorsModel } from '../models/stateful/operatorsModel.js';
import TopicsRuleCreationDialog from './topicsRuleCreation.js';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const videoCategoryDict = {
    "Autos & Vehicles": 2, 
    "Film & Animation": 1, 
    "Music": 10, 
    "Pets & Animals": 15, 
    "Sports": 17, 
    "Short Movies": 18, 
    "Travel & Events": 19, 
    "Gaming": 20, 
    "Videoblogging": 21, 
    "People & Blogs": 22, 
    "Comedy": 23, 
    "Entertainment": 24, 
    "News & Politics": 25, 
    "Howto & Style": 26, 
    "Education": 27, 
    "Science & Technology": 28, 
    "Nonprofits & Activism": 29, 
    "Movies": 30, 
    "Anime/Animation": 31, 
    "Action/Adventure": 32, 
    "Classics": 33, 
    "Comedy": 34, 
    "Documentary": 35, 
    "Drama": 36, 
    "Family": 37, 
    "Foreign": 38, 
    "Horror": 39, 
    "Sci-Fi/Fantasy": 40, 
    "Thriller": 41, 
    "Shorts": 42, 
    "Shows": 43, 
    "Trailers": 44
  }

function getOptions (field, operatorHandler, operators_info) {
    var record = [''];
    switch(field) {
      case 'template':
        record = operatorHandler.prompts(operators_info);
        break;
      case 'Channels':
        record = operators_info[0]['Channels'];
        break;
      case 'mode':
        record = ["title", "full", ""];
        break;
      case 'canvas':
        record = Object.keys(operatorHandler.canvases(operators_info));
        break;
      case 'watermark':
        record = Object.keys(operatorHandler.watermarks(operators_info));
        break;
      case 'VideoCategory':
        record = ["Autos & Vehicles", "Film & Animation", "Music", "Pets & Animals", "Sports", "Short Movies", "Travel & Events", "Gaming", "Videoblogging", "People & Blogs", "Comedy", "Entertainment", "News & Politics", "Howto & Style", "Education", "Science & Technology", "Nonprofits & Activism", "Movies", "Anime/Animation", "Action/Adventure", "Classics", "Comedy", "Documentary", "Drama", "Family", "Foreign", "Horror", "Sci-Fi/Fantasy", "Thriller", "Shorts", "Shows", "Trailers"]
        break;
      case 'MediaType':
        record = ["text", "image", "video"];
        break;
      case 'PrivacyStatus':
        record = ["public", "private", "unlisted"];
        break;
    }
    if (!record.includes('')) {
      record.push('');
    }
    return record;
}

function select(ids, topics, setSelectedTopics) {
    const selectedIDs = new Set(ids);
    const selectedRows = topics.filter((row) =>
        selectedIDs.has(row.id),
    );
    setSelectedTopics(selectedRows);
    if (selectedIDs.size > 0) {
        return true;
    } else {
       return false;
    }
}

class AddTopicsTableBlock extends React.PureComponent {

  constructor(props) {
    super(props);
  }

  render() {

    const handleOpenRuleCreationDialogClose = () => {
        this.props.setOpenRuleCreationDialog(false);
        
    }

    const add_topics_table_columns = [
        { field: 'Title', headerName: 'Title', width: 300, editable: true },
        { field: 'template', headerName: 'Template', width: 200, editable: true, type: "singleSelect" ,
          valueOptions: ({ row }) => {
            return getOptions ('template', this.props.operatorHandler, this.props.state.operators_info)
          }
        },
        { field: 'Channels', headerName: 'Channels', width: 160, editable: true, type: "singleSelect", 
            valueOptions: ({ row }) => {
              return getOptions ('Channels', this.props.operatorHandler, this.props.state.operators_info)
            }
        },
        { field: 'rule', headerName: 'Publishing rule', width: 200, editable: true,
          valueGetter: (row) => {
            const rowToUpdateIndex = row['id'];
            var elementPos = this.props.state.rules.map(function(x) {return x.id; }).indexOf(rowToUpdateIndex);
            if (elementPos === -1) {
              row.value = null;
              return null;
            }
            row.value = this.props.state.rules[elementPos].rule;
            //this.props.updateRow(row);
            return this.props.state.rules[elementPos].rule;
          }
        },
        { field: 'mode', headerName: 'Mode', width: 100, editable: true, type: "singleSelect", 
            valueOptions: ({ row }) => { return getOptions ('mode', this.props.operatorHandler, this.props.state.operators_info) }
        },
        { field: 'canvas', headerName: 'Canvas', width: 100, editable: true, type: "singleSelect" ,
          valueOptions: ({ row }) => { return getOptions ('canvas', this.props.operatorHandler, this.props.state.operators_info) }
        },
        { field: 'watermark', headerName: 'Watermark', width: 100, editable: true, type: "singleSelect" ,
          valueOptions: ({ row }) => { return getOptions ('watermark', this.props.operatorHandler, this.props.state.operators_info) }
        },
        { field: 'MediaType', headerName: 'Media type', width: 100, editable: true, type: "singleSelect" ,
          valueOptions: ({ row }) => { return getOptions ('MediaType', this.props.operatorHandler, this.props.state.operators_info) }
        },
        { field: 'VideoCategory', headerName: 'Video category', width: 130, editable: true, type: "singleSelect" ,
          valueOptions: ({ row }) => { return getOptions ('VideoCategory', this.props.operatorHandler, this.props.state.operators_info) }
        },
        { field: 'PrivacyStatus', headerName: 'Privacy Status', width: 130, editable: true, type: "singleSelect" ,
          valueOptions: ({ row }) => { return getOptions ('PrivacyStatus', this.props.operatorHandler, this.props.state.operators_info) }
        },
    ];
    
    return (
        <div style={{ height: 400, width: '100%' }}>
        <TopicsRuleCreationDialog 
            open={this.props.state.openRuleCreationDialog}
            handleClose={handleOpenRuleCreationDialogClose}
            curId={this.props.state.curId}
            rules={this.props.state.rules}
            setRules={this.props.setRules}
        />
        <DataGrid
            rows={this.props.state.topics}
            columns={add_topics_table_columns}
            checkboxSelection
            onCellEditCommit={row => {
                this.props.updateRow(row);
              }
            }
            onSelectionModelChange={(ids) => {
                const enableDeleteBtn = !select(ids, this.props.state.topics, this.props.setSelectedTopics);
                this.props.setDeleteBtnDisabled(enableDeleteBtn);
            }}
            onCellClick={row => {
                const field = row['field'];
                this.props.setCurId(row['id']);
                this.props.setOpenRuleCreationDialog(field === 'rule');
                
            }}
        />
        </div>
    );
  }
}

class AddTopicsTable extends React.PureComponent {
  constructor(props) {
      super(props);
  }

  render() {
    if (this.props.state.loading || this.props.state.operators_info === null) {
      return (<LoadingBlock />)
    } else {
      return (<AddTopicsTableBlock
                  operatorHandler={this.props.operatorHandler}
                  state={this.props.state}
                  setState={this.props.setState}
                  setDeleteBtnDisabled={this.props.setDeleteBtnDisabled}
                  setOpenRuleCreationDialog={this.props.setOpenRuleCreationDialog}
                  setSelectedTopics={this.props.setSelectedTopics}
                  setTopics={this.props.setTopics}
                  setCurId={this.props.setCurId}
                  setRules={this.props.setRules}
                  updateRow={this.props.updateRow}
              />)
  }

  }
}


export default class TopicsCreateDialog extends React.PureComponent {

    constructor(props) {
      super(props);
      this.state = {
        topics: [{id: 0, ChannelType: '', ChannelAccountId: '', Category: ''}],
        selected_topics: [],
        record_num: 0,
        max_id: 0,
        operators_info: null,
        loading: true,
        deleteBtnDisabled: true,
        openErrorAlertDialog: false,
        rules: [{id: 0, rule: ''}],
        openRuleCreationDialog: false,
        curId: 0
      };
    }

    componentDidMount() {

      this.topicsHandler = new TopicsModel(this.props.tokenHandler);
      this.operatorHandler = new OperatorsModel(this.props.tokenHandler);
  
      const callbackFunc = (raw_records) => {
        this.setState({ 
          operators_info: raw_records,
          loading: false,
        });
      };
  
      this.operatorHandler.list({'channels': true, 'prompts': true, 'canvases': true, 'watermarks': true}, callbackFunc);
    }

    render() { 
      const setDeleteBtnDisabled = (value) => {
        this.setState({
          deleteBtnDisabled: value
        });
      };
  
      const setOpenRuleCreationDialog = (value) => {
        this.setState({
          openRuleCreationDialog: value
        });
      };

      const setSelectedTopics = (value) => {
        this.setState({
          selected_topics: value
        });
      };

      const setTopics = (value) => {
        this.setState({
          topics: value
        });
      };

      const setCurId = (value) => {
        this.setState({
          curId: value
        });
      };

      const setRules = (value) => {
        this.setState({
          rules: value
        });
      };

      const updateRow = (row) => {
        const rowToUpdateIndex = row['id'];
        const field = row['field'];
        const value = row['value'];
        var elementPos = this.state.topics.map(function(x) {return x.id; }).indexOf(rowToUpdateIndex);
        this.setState(state => ({
          topics: state.topics.map((row, index) => index === elementPos ? { ...row, [field]: value } : row)
        }))
      }

        const addNewTopic = () => {
          this.setState(state => ({
            topics:  [...state.topics, {id: state.max_id + 1, ChannelType: '', ChannelAccountId: '', Category: ''}],
            max_id: state.max_id+1,
            record_num: state.record_num + 1,
            rules: [...state.rules, {id: state.max_id + 1, rule: ''}]
          }))
        }

        const removeTopics = () => {
          this.state.selected_topics.forEach(element => {
            const rowToDeleteIndex = element['id'];
            var elementPos = this.state.topics.map(function(x) {return x.id; }).indexOf(rowToDeleteIndex);
            this.setState(state => ({
              topics: [...state.topics.slice(0, elementPos), ...state.topics.slice(elementPos + 1)],
              rules: [...state.rules.slice(0, elementPos), ...state.rules.slice(elementPos + 1)]
            }))
          });
          this.setState(state => ({
            record_num: state.record_num - state.selected_topics.size,
            selected_topics: []
          }))
        }

        const postprocess = () => {
          this.props.handleClose();
        }

        const commitNewTopics = () => {
          var body = this.state.topics.filter((topic) => (topic['Title'] !== undefined && topic['Channels'] !== undefined));
          if (body.length !== this.state.topics.length) {
            this.setState({
              openErrorAlertDialog: true
            })
            return;
          }

          var canvasDict = this.operatorHandler.canvases(this.state.operators_info);
          var watermarkDict = this.operatorHandler.watermarks(this.state.operators_info);
          
          body.forEach((record, i) => {
                            record.canvas = canvasDict[record.canvas];
                            record.watermark = watermarkDict[record.watermark];
                            record.rule = this.state.rules[i].rule;
                            record.VideoCategory = videoCategoryDict[record.VideoCategory];
                            return record;
          });

          this.topicsHandler.create(body, postprocess, []);
          this.setState({
            topics: [{id: 0, ChannelType: '', ChannelAccountId: '', Category: ''}],
            selected_topics: [],
            record_num: 0,
            max_id: 0,
            rules: [{id: 0, rule: ''}]
          });
          this.props.handleClose();
        }
    
    return (
    <div>
        <ErrorAlertDialog
            open={this.state.openErrorAlertDialog}
            setOpen={this.state.setOpenErrorAlertDialog}
            title="Incomplete topic information."
            details="You are missing either of Title, Type, Channel or Category in one or more topics. No update will be performed."/>
        
        <Dialog
        fullScreen
        open={this.props.open}
        onClose={this.props.handleClose}
        TransitionComponent={Transition}
        >
        <AppBar sx={{ position: 'relative' }}>
            <Toolbar>
            <IconButton edge="start" color="inherit" onClick={this.props.handleClose} aria-label="close" ><CloseIcon /></IconButton>
            <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">Add New Topics in Batch</Typography>
            <Button autoFocus color="inherit" onClick={addNewTopic}>add a new topic</Button>
            <Button autoFocus disabled={this.state.deleteBtnDisabled} color="inherit" onClick={removeTopics}>remove topics</Button>
            <Button autoFocus color="inherit" onClick={commitNewTopics}>submit</Button>
            </Toolbar>
        </AppBar>
        <List>
            <ListItem>
            <AddTopicsTable state={this.state} operatorHandler={this.operatorHandler} 
            setDeleteBtnDisabled={setDeleteBtnDisabled}
            setOpenRuleCreationDialog={setOpenRuleCreationDialog}
            setSelectedTopics={setSelectedTopics}
            setTopics={setTopics}
            setCurId={setCurId}
            setRules={setRules}
            updateRow={updateRow}/>
            </ListItem>
        </List>
        </Dialog>
    </div>
    );
  }
}
